<template>
  <div class="rootView">

    <view class="devListView">

      <!--   设备1    320-28-28=264 padding会使view变大 -->
      <view
        :class="{'devListView_item':true, 'active':itemPositon== 1}"
        @mouseover="() => handleItemMouseOver(1)"
        @mouseleave="() => handleItemMouseOver(0)"

        @click="() => handleProductItemClick(1)"
      >
        <el-image
          :src="require('../../assets/product/product_zhiyin_sy_1.png')"
          fit="contain"
          style="
                width: 4.00rem;
                height: 4.00rem;

                position:absolute;
              "
          lazy
        />
        <text
          style="
                width: 4.0rem;
                height: auto;
                text-align: center;

                font-size: 0.18rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;

                position:absolute;
                bottom: 0.2rem;

              ">
          商用直饮水机
        </text>

      </view>

      <!--   设备2     -->
      <view
        :class="{'devListView_item':true, 'active':itemPositon== 2}"
        @mouseover="() => handleItemMouseOver(2)"
        @mouseleave="() => handleItemMouseOver(0)"

        @click="() => handleProductItemClick(2)"
      >
        <el-image
          :src="require('../../assets/product/product_zhiyin_cs_1.png')"
          fit="contain"
          style="
                width: 4.00rem;
                height: 4.00rem;

                position:absolute;
              "
          lazy
        />
        <text
          style="
                width: 4.0rem;
                height: auto;
                text-align: center;

                font-size: 0.18rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;

                position:absolute;
                bottom: 0.2rem;

              ">
          家用纯水机
        </text>

      </view>


    </view>


  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  import {ref} from "vue";

  export default {
    name: "ProductListView4",

    setup() {

      const router = useRouter()

      const itemPositon = ref('0')//tag编号

      //item点击函数定义
      const handleProductItemClick = (productId) => {
        console.log("handleProductItemClick=======> productId: "+productId);

        /*产品1*/
        if(productId == 1){
          router.push(`/productdetail_7`)
        }
        else if(productId == 2){
          router.push(`/productdetail_8`)
        }

      }

      //item鼠标上移函数定义
      const handleItemMouseOver = (positon) => {
        itemPositon.value = positon;
      }


      return { handleProductItemClick, itemPositon, handleItemMouseOver }
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/
  }

  .devListView {
    width: auto;
    height: 4.6rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 0.8rem;
    margin-left: 1.2rem;
    /*margin-bottom: 0.8rem; 底部栏统一设置：margin-top:80了 */

    &_item{
      width: 4.00rem; /*ps:图片已自带边框间隔（padding），所以不用算padding了*/
      height: 4.00rem;

      margin-right: 0.7rem;

      background: #f2f9ff;


      position: relative;

    }

  }


  /*鼠标放置，激活效果*/
  .active{

    /*element-ui shadow效果*/
    box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.12),0 0 0.12rem rgba(0,0,0,0.04);

    z-index: 999;
    background: rgba(199, 199, 203, 0.5);

  }

</style>
