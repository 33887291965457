<template>
  <div class="rootView">

    <view class="ContentView">

      <!-- item1 -->
      <view class="ContentView_Item">

        <view class="ContentView_Item_left">

          <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
          <view class="itemTitle"
                style="margin-top: 0.0rem">
            <el-image class="itemTitle_img"
                      :src="require('../../assets/aboutus/us_title3@2x.png')"
                      fit="cover"
                      lazy
            />
            <text class="itemTitle_txt">
              河北创美科技有限公司
            </text>
          </view>
          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.39rem;
            ">
            地址：石家庄高新区珠江大道313号方亿科技园C区3号楼3单元301厂房

          </text>

          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.17rem;
            ">
            咨询电话：0311-88802333
          </text>


        </view>

        <view class="ContentView_Item_right">

          <el-image
            :src="require('../../assets/aboutus/map@2x.png')"
            fit="cover"
            style="
              width: 100%;
              height: 100%;"
            lazy
          />

        </view>


      </view>


      <!-- 横线 -->
      <view style="
        width: 14.41rem;
        height: 0.02rem;
        background: #CCCCCC;

        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        ">
      </view>


      <!-- item2 -->
      <view v-show="false" class="ContentView_Item">

        <view class="ContentView_Item_left">

          <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
          <view class="itemTitle"
                style="margin-top: 0.0rem">
            <el-image class="itemTitle_img"
                      :src="require('../../assets/aboutus/us_title3@2x.png')"
                      fit="cover"
                      lazy
            />
            <text class="itemTitle_txt">
              河北创美建材科技有限公司
            </text>
          </view>
          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.39rem;
            ">
            地址：石家庄高新区珠江大道313号方亿科技园C区3号楼3单元301厂房
          </text>

          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.17rem;
            ">
            咨询电话：18033786165  0311-88802333
          </text>


        </view>

        <view class="ContentView_Item_right">

          <el-image
            :src="require('../../assets/aboutus/map@2x.png')"
            fit="cover"
            style="
              width: 100%;
              height: 100%;"
            lazy
          />

        </view>


      </view>


      <!-- 横线 -->
      <view v-show="false" style="
        width: 14.41rem;
        height: 0.02rem;
        background: #CCCCCC;

        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        ">
      </view>


      <!-- item3 -->
      <view class="ContentView_Item">

        <view class="ContentView_Item_left">

          <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
          <view class="itemTitle"
                style="margin-top: 0.0rem">
            <el-image class="itemTitle_img"
                      :src="require('../../assets/aboutus/us_title3@2x.png')"
                      fit="cover"
                      lazy
            />
            <text class="itemTitle_txt">
              深圳美创环境科技有限公司
            </text>
          </view>
          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.39rem;
            ">
            地址：深圳市南山区粤海街道麻岭社区深南大道9966号威盛科技大厦2108

          </text>

          <!-- 文字介绍-->
          <text
            style="
              width: auto;
              height: 0.17rem;
              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              margin-left: 0rem;
              margin-top: 0.17rem;
            ">
            咨询电话：13392835784

          </text>

        </view>

        <view class="ContentView_Item_right">

          <el-image
            :src="require('../../assets/aboutus/map2@2x.png')"
            fit="cover"
            style="
              width: 100%;
              height: 100%;"
              lazy
          />

        </view>


      </view>




    </view>

  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "ContactUsSubView",

    setup() {

      //const router = useRouter()



    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .ContentView{
    width: 14.40rem;
    height: auto;  /* 所有view高度和*/
    background-color: #FFFFFF;
    margin-left: 2.40rem;
    margin-top: 0.80rem;

    display: flex;
    flex-direction: column;

    &_Item{

      width: 100%;
      height: 4.0rem;

      display: flex;
      flex-direction: row;

      &_left{
        width: 50%;
        height: 100%;

        display: flex;
        flex-direction: column;
      }

      &_right{
        width: 50%;
        height: 100%;
      }


    }




  }


  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 0rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }



</style>
