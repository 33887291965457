<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 2.52rem; height: 0.1rem;position: absolute;"
        src="../../assets/home/title_bg@2x.png"
        alt />

      <text
        style="
          width: 1.12rem;
          height: 0.29rem;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
        ">
        资质荣誉
      </text>
    </view>

    <view class="centerView">
      <el-image
        :src="require('../../assets/aboutus/us_certificate2_1.jpg')"
        fit="contain"
        style="
          width: 14.20rem;
          height: 3.17rem;
        "

      />

      <el-image
        :src="require('../../assets/aboutus/us_certificate3.jpg')"
        fit="contain"
        style="
          width: 14.20rem;
          height: 2.97rem;
          margin-top: 1.0rem;
        "
        lazy
      />

      <el-image
        :src="require('../../assets/aboutus/us_certificate4_1.jpg')"
        fit="contain"
        style="
          width: 14.20rem;
          height: 4.27rem;
          margin-top: 1.0rem;
      "

      />

      <el-button type="info"
         @click="handleLookMore()"
         style="
            width: 1.50rem;
            height: 0.5rem;
            background: rgb(187, 187, 187);
            border-radius: 0.24rem;
            font-size: 0.17rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: rgb(255, 255, 255);
            margin-top: 0.5rem;
            margin-left: 0rem;
            margin-bottom: 0.4rem;
         ">
        查看更多》
      </el-button>
    </view>

  </div>
</template>

<script>


  import {useRouter} from "vue-router";
  import { useStore } from 'vuex'
  import Bus from "../../service/bus";

  export default {
    name: "Honor",

    setup() {
      const router = useRouter()
      const store = useStore()

      /*跨组件事件发送*/
      const sendEvent= (eventname, data) =>{
        Bus.$emit(eventname, data);
      }

      //查看详情
      const handleLookMore = () => {
        let discard = false;
        let index =3;
        store.commit('changeAboutusRoute', {discard, index });//更新数据

        router.push(`/aboutUs`)
        sendEvent("changeAboutusRoute", 3);
      }

      return { handleLookMore }

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;
    /*background-color: #F0F0F0;*/
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 0.6rem;

  }

  .titleView{
    width: 100%;
    height: 1.23rem;
    margin-top: 0.37rem;
    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .centerView {
    width: 14.4rem;
    height: 14rem;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }




</style>
