<template>
  <div class="rootView">
    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_shangwu_1.png')"
      fit="contain"
      style="
        width: 14.4rem;
        height: 6.3rem;
        margin-left: 2.4rem;
        margin-top: 0.8rem;
      "
      lazy
    />

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_shangwu_2.png')"
      fit="contain"
      style="
        width: 14.4rem;
         height: 5.1rem;
        margin-left: 2.4rem;
        margin-top: 1.2rem;
      "
      lazy
    />

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_shangwu_3.png')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 5.30rem;
        margin-left: 2.5rem;
        margin-top: 0.9rem;
      "
      lazy
    />
  </div>
</template>


<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "Solutions_2",

    setup() {

      //const router = useRouter()

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }




</style>
