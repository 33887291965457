<template>
  <div class="wrapper">

    <!-- 顶部导航 -->
    <Header :currentIndex="4"/>

    <AdvantagesContentView/>

    <!-- 底部栏1 -->
    <Footer @backTop="backTop()" @showMsgDialog="showMsgDialog(true)"/>

  </div>

  <!--  留言版  -->
  <div v-if="isShowMsgDialog" class="leave-msg-dialog">
    <MessageBoard @closeMsgDialog="showMsgDialog(false)"/>
  </div>

</template>



<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";
  // import { ref } from 'vue'


  // 回到顶部
  import { onMounted, onUnmounted } from 'vue'
  import backToTopEffect from '../../effects/backToTopEffect'

  //留言
  import { ref } from 'vue'
  import MessageBoard from "../../components/MessageBoard";
  import AdvantagesContentView from "./AdvantagesContentView";

  export default {

    name: 'ProjectCases',
    components: {AdvantagesContentView, Header,Footer,MessageBoard},


    setup() {

      //回到顶部
      const { addLisener, removeLisener, backTop } = backToTopEffect();

      onMounted(() => {
        //console.log('onMounted-----------------------------------Home.vue')

        // 回到顶部 滚动条所在节点，添加监听
        const scrollDom = Array.from(document.getElementsByClassName('wrapper'));
        addLisener(scrollDom)

      });

      onUnmounted(() => {
        //console.log('onUnmounted-----------------------------------Home.vue')

        //回到顶部 滚动条所在节点，移除监听
        removeLisener();

      });


      //留言
      const isShowMsgDialog = ref(false)//是否显示留言板
      const showMsgDialog = (showit) => {
        isShowMsgDialog.value = showit;
      }

      return {backTop , isShowMsgDialog, showMsgDialog}

    }



  }
</script>



<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .wrapper {
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 .0rem .0rem .0rem;

  }


</style>

