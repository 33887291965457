<!--产品资讯-->

<template>
  <div class="rootView">

    <view class="viewsItem" style="margin-top: 0.8rem"
          :class="{'news_item':true, 'active':itemPositon== 3}"
          @mouseover="() => handleItemMouseOver(3)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(3)"
    >

      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/chanpin/chanpin-news-3.jpg')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          石家庄社区直饮水升级改造惠民工程启动
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          5月10日上午，石家庄社区直饮水升级改造惠民工程开工仪式在石家庄市长安区谈后社区举行。作为省会改善民生的重点工程之一，本次社区直饮水升级饮用水意在为全市社区居民提供更有保障、更加方便、更加实惠的饮用水服务，用实际行动提升人民生活品质。
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 1.1rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2022.05.16
        </text>
      </view>

    </view>


    <!--  横线  -->
    <view
      style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"
    />


    <view class="viewsItem" style="margin-top: 0.8rem"
          :class="{'news_item':true, 'active':itemPositon== 2}"
          @mouseover="() => handleItemMouseOver(2)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(2)"
    >

      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/home/product/product_clener_biaozhun.png')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          创美中央直饮水系统工艺流程
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 1.1rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2021.08.16
        </text>
      </view>

    </view>


    <!--  横线  -->
    <view
      style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"
    />

    <view class="viewsItem" style="margin-top: 0.41rem"
          :class="{'news_item':true, 'active':itemPositon== 1}"
          @mouseover="() => handleItemMouseOver(1)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(1)"
    >
      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/news-1.png')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          创美智慧管道直饮水开启健康饮水新时代
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 1.1rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2021.08.16
        </text>
      </view>

    </view>





  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  import {ref} from "vue";

  export default {
    name: "News_2",

    setup() {

      const router = useRouter()

      const itemPositon = ref('1')//tag编号

      //item点击函数定义
      const handleItemClick = (itemId) => {
        console.log("handleItemClick=======> itemId: "+itemId);

        /*新闻 item点击*/
        if(itemId == 1){
          router.push(`/news_chanpin_detail_1`)
        }
        else if(itemId == 2){
          router.push(`/news_chanpin_detail_2`)
        }
        else if(itemId == 3){
          router.push(`/news_chanpin_detail_3`)
        }


      }

      //item鼠标上移函数定义
      const handleItemMouseOver = (positon) => {
        itemPositon.value = positon;
      }


      return { handleItemClick, itemPositon, handleItemMouseOver}

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .viewsItem{
    width: 14.40rem;
    height: 3.0rem;
    margin-left: 2.4rem;

    display: flex;
    flex-direction: row;

    &__left{
      width: 5.0rem;
      height: 100%;

    }
    &__right{
      width: 8.3rem;
      height: 100%;
      margin-left: 0.7rem;

      display: flex;
      flex-direction: column;
    }

  }

  .news_item{
    background-color: white;
  }
  /*鼠标放置，激活效果*/
  .active{
    background-color: #f0f0f0;

    /*element-plus 阴影效果*/
    box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.12),0 0 0.12rem rgba(0,0,0,0.04);
    z-index: 999;
  }


</style>
