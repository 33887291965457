<template>
  <div class="rootView">


    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 2.52rem; height: 0.1rem;position: absolute;"
        src="../../assets/home/title_bg@2x.png"
        alt />

      <text
        style="
          width: 1.12rem;
          height: 0.29rem;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
        ">
        产品中心
      </text>
    </view>

    <!-- tab按钮   -->
    <view class="tabView">
      <!--   动态绑定css   :class="{'active':isActive==-1}-->
      <el-button :class="{'tabView_tab1':true, 'active':devType==1}"
                 type="primary" plain round
                 @click="() => handleTabClick(1)"
                 @mouseover="() => handleTabMouseOver(1)"
                 @mouseleave="() => handleTabMouseOver(0)"
        >
        智能一体式设备
      </el-button>
      <el-button :class="{'tabView_tab2':true, 'active':devType==2}"
                 type="primary" plain round
                 @click="() => handleTabClick(2)"
                 @mouseover="() => handleTabMouseOver(2)"
                 @mouseleave="() => handleTabMouseOver(0)">
        设备组件
      </el-button>
      <el-button :class="{'tabView_tab3':true, 'active':devType==3}"
                 type="primary" plain round
                 @click="() => handleTabClick(3)"
                 @mouseover="() => handleTabMouseOver(3)"
                 @mouseleave="() => handleTabMouseOver(0)">
        饮水终端
      </el-button>
      <el-button :class="{'tabView_tab4':true, 'active':devType==4}"
                 type="primary" plain round
                 @click="() => handleTabClick(4)"
                 @mouseover="() => handleTabMouseOver(4)"
                 @mouseleave="() => handleTabMouseOver(0)">
        直饮水机
      </el-button>
    </view>


    <view class="devView">

      <!-- 智能一体式设备   -->
      <!--      <view v-if="devType==1" class="devView_item">  change 2021.8.13 改为鼠标放置不切换，仅点击跳转-->
      <view class="devView_item">
        <view class="devView_item_top">


          <!--   左箭头     -->
          <el-image
            :src="require('../../assets/home/product/left2@2x.png')"
            fit="cover"
            style="
            width: 0.32rem;
            height: 0.5rem;

            position:absolute;
            top: 1.35rem;
            left: 0;
          "
          />

          <!--   右箭头     -->
          <el-image
            :src="require('../../assets/home/product/right2@2x.png')"
            fit="cover"
            style="
            width: 0.32rem;
            height: 0.5rem;

            position:absolute;
            top: 1.35rem;
            right: 0;
          "
          />


          <!--   设备1    320-28-28=264 padding会使view变大 -->
          <el-image
            :src="require('../../assets/home/product/product_clener_biaozhun.png')"
            fit="cover"
            :class="{ 'active2':itemPositon== 1}"
            @click="handleProductListItemClick(1)"
            @mouseover="handleItemMouseOver(1)"
            @mouseleave="handleItemMouseOver(0)"
            style="
              width: 2.64rem;
              height: 2.64rem;

              position:absolute;
              top: 0;
              left: 0.50rem;


              border: 0.01rem solid #DDDDDD;
              padding: 0.28rem;

            "
            lazy
          />
          <text
            style="
              width: 3.2rem;
              height: auto;
              text-align: center;

              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              position:absolute;
              top: 3.36rem;
              left: 0.50rem;

            ">
            智能一体化设备（标准版）
          </text>


          <!--   设备2     -->
          <el-image
            :src="require('../../assets/home/product/product_cleaner_jichu1.png')"
            fit="cover"
            :class="{ 'active2':itemPositon== 2}"
            @click="handleProductListItemClick(2)"
            @mouseover="handleItemMouseOver(2)"
            @mouseleave="handleItemMouseOver(0)"
            style="
              width: 2.64rem;
              height: 2.64rem;

              position:absolute;
              top: 0;
              left: 3.90rem;

              border: 0.01rem solid #DDDDDD;
              padding: 0.28rem;

            "
            lazy
          />
          <text
            style="
              width: 3.2rem;
              height: auto;
              text-align: center;

              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              position:absolute;
              top: 3.36rem;
              left: 3.90rem;

            ">
            智能一体化设备（基础版）
          </text>


          <!--   设备3     -->
          <el-image
            :src="require('../../assets/home/product/water_box_1.png')"
            fit="cover"
            :class="{ 'active2':itemPositon== 3}"
            @click="handleProductListItemClick(3)"
            @mouseover="handleItemMouseOver(3)"
            @mouseleave="handleItemMouseOver(0)"
            style="
              width: 2.64rem;
              height: 2.64rem;

              position:absolute;
              top: 0;
              left: 7.30rem;

              border: 0.01rem solid #DDDDDD;
              padding: 0.28rem;

            "
            lazy
          />
          <text
            style="
              width: 3.2rem;
              height: auto;
              text-align: center;

              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              position:absolute;
              top: 3.36rem;
              left: 7.30rem;

            ">
            无菌水箱
          </text>



          <!--   设备4     -->
          <el-image
            :src="require('../../assets/home/product/iot_watermeter2.png')"
            fit="cover"
            :class="{ 'active2':itemPositon== 4}"
            @click="handleProductListItemClick(4)"
            @mouseover="handleItemMouseOver(4)"
            @mouseleave="handleItemMouseOver(0)"
            style="
              width: 2.64rem;
              height: 2.64rem;

              position:absolute;
              top: 0;
              left: 10.70rem;

              border: 0.01rem solid #DDDDDD;
              padding: 0.28rem;

            "
            lazy
          />
          <text
            style="
              width: 3.2rem;
              height: auto;
              text-align: center;

              font-size: 0.16rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

              position:absolute;
              top: 3.36rem;
              left: 10.70rem;

            ">
            NB-IOT物联网水表
          </text>


        </view>


        <view class="devView_item_bottom">

          <!--   左箭头     -->
          <el-image
            :src="require('../../assets/home/product/left2@2x.png')"
            fit="cover"
            style="
            width: 0.32rem;
            height: 0.5rem;

            position:absolute;
            top: 1.44rem;
            left: 0;
          "
          />

          <!--   右箭头     -->
          <el-image
            :src="require('../../assets/home/product/right2@2x.png')"
            fit="cover"
            style="
            width: 0.32rem;
            height: 0.5rem;

            position:absolute;
            top: 1.44rem;
            right: 0;
          "
          />

          <!--   设备图    670-28-28=614   340-28-28=284-->
          <el-image
            :src="require('../../assets/home/product/product1@2x.png')"
            fit="cover"
            style="
              width: 6.15rem;
              height: 2.84rem;
              background: #F0F6FC;

              position:absolute;
              top: 0;
              left: 0.5rem;

              padding: 0.28rem;

            "
            lazy
          />

          <view class="devView_item_bottom_right">

            <text
              style="
                width: auto;
                height: 0.2rem;
                font-size: 0.2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;


                margin-top: 0.68rem;
                margin-left: 0.71rem;


            ">
              智慧管道直饮水系统
            </text>

            <text
              style="
                width: 5.39rem;
                height: 0.8rem;
                font-size: 0.16rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.32rem;
                opacity: 0.7;

                margin-top: 0.29rem;
                margin-left: 0.71rem;
                margin-right: 0.60rem;

            ">
              设备采用目前前沿工艺制造，具有高度集成化、安全性高、故障率低、维护维修便捷、安装便捷、占地面积小等优异特点，根据具体场景需求定制个性化方案。
            </text>

            <el-button type="info"
              @click="handleLookDetail()"
              style="
                width: 1.54rem;
                height: 0.48rem;
                background: #BBBBBB;
                border-radius: 0.24rem;

                font-size: 0.18rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;

                margin-top: 0.51rem;
                margin-left: 0.71rem;
            ">
              查看详情》
            </el-button>


          </view>



        </view>

      </view>

      <!-- 其他类别设备    change 2021.8.13 改为鼠标放置不切换，仅点击跳转 -->
      <!-- <view v-if="devType==2"  class="devView_item">

      </view>-->

    </view>



  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  import { ref } from 'vue'
  import {useStore} from "vuex";

  export default {
    name: "ProductCenter",

    setup() {

      const router = useRouter()
      const store = useStore()

      const devType = ref(1)//1.智能一体式设备  2.其他类别设备

      //tab点击函数定义
      const handleTabClick = (type) => {
        if(type == 1){
          // devType.value = 1;
          //
          // router.push(`/products`)
          // console.log("handleTabClick=======1")
          let discard = false;
          let index =1;
          store.commit('changeProductsRoute', {discard, index });//更新数据
        }
        else if(type == 2){
          // devType.value = 2;
          // console.log("handleTabClick=======2")
          let discard = false;
          let index =2;
          store.commit('changeProductsRoute', {discard, index });//更新数据
        }
        else if(type == 3){
          // devType.value = 2;
          // console.log("handleTabClick=======2")
          let discard = false;
          let index =3;
          store.commit('changeProductsRoute', {discard, index });//更新数据
        }
        else if(type == 4){
          // devType.value = 2;
          // console.log("handleTabClick=======2")
          let discard = false;
          let index =4;
          store.commit('changeProductsRoute', {discard, index });//更新数据
        }

        devType.value = type;
        router.push(`/products`)

      }

      //tab鼠标上移函数定义
      const handleTabMouseOver = (type) => {
        devType.value = type;
      }


      //产品列表 item点击
      const handleProductListItemClick = (productId) => {

        if(productId == 1){
          console.log("handleProductListItemClick=======1")
          router.push(`/productdetail_1`)
        }
        else if(productId == 2){
          console.log("handleProductListItemClick=======2")
          router.push(`/productdetail_2`)
        }
        else if(productId == 3){
          console.log("handleProductListItemClick=======3")
          router.push(`/productdetail_3`)
        }
        else if(productId == 4){
          console.log("handleProductListItemClick=======4")
          router.push(`/productdetail_4`)
        }



      }

      //查看详情
      const handleLookDetail = () => {
        router.push(`/solutions`)
      }


      //产品设备图
      const itemPositon = ref('0')//tag编号
      //tab鼠标上移函数定义
      const handleItemMouseOver = (type) => {
        itemPositon.value = type;
      }

      return { handleTabClick, handleTabMouseOver, devType, handleProductListItemClick, handleLookDetail ,itemPositon ,handleItemMouseOver}

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: 10rem;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/

    overflow-x: hidden;
    overflow-y: hidden;

    margin-top: -0.7rem;
  }

  .titleView{
    width: 100%;
    height: 1.23rem;
    margin-top: 0.38rem;
    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .tabView{
    width: 100%;
    height: 0.48rem;

    display: flex;
    flex-direction: row;
    justify-content: center;

    &_tab1 {
      width: 1.86rem;
      height: 100%;
    }

    &_tab2 {
      width: 1.86rem;
      height: 100%;
      margin-left: 0.2rem;
    }

    &_tab3 {
      width: 1.86rem;
      height: 100%;
      margin-left: 0.2rem;
    }

    &_tab4 {
      width: 1.86rem;
      height: 100%;
      margin-left: 0.2rem;
    }

  }

  .devView{
    width: 100%;
    height: 7.39rem;

    display: flex;
    justify-content: center;

    &_item{
      width: 14.40rem;
      height: 100%;
      display: flex;
      flex-direction: column;

      &_top{
        width: 100%;
        height: 3.99rem;
        /*background-color: blue;*/

        margin-top: 0.40rem;

        position: relative;
      }

      &_bottom{
        width: 100%;
        height: 3.4rem;
        /*background-color: red;*/

        margin-top: 0.40rem;

        position: relative;

        /*670+50=720*/
        &_right{
          width: 6.70rem;
          height: 3.4rem;
          /*background: #F0F0F0;*/
          background: white;

          position: absolute;
          top:0;
          left: 7.20rem;

          display: flex;
          flex-direction: column;

        }
      }

    }


  }

  /*选中设备类型后的，按钮激活效果*/
  .active{
    background: #3399FF;
    color: white;
  }

  /*鼠标放置，激活效果*/
  .active2{

    /*element-ui shadow效果*/
    box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.12),0 0 0.12rem rgba(0,0,0,0.04);

    z-index: 999;
    /*background: rgba(199, 199, 203, 0.5);*/

    /*border: 0.02rem solid #3399FF;*/

  }

 /* .el-image >>> img.el-image__inner{
    background: #F2F9FF;
  }*/

/*  .el-image >>> img.el-image__inner{    todo:正式环境下会生效！！！！！！！！！！！！！！！
    background: #F2F9FF;
  }*/


</style>
