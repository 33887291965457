<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 2.52rem; height: 0.1rem;position: absolute;"
        src="../../assets/home/title_bg@2x.png"
        alt />

      <text
        style="
          width: 1.12rem;
          height: 0.29rem;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
        ">
        创美优势
      </text>
    </view>

    <view class="centerView"
          @click="handleLookMore()">
      <el-image
        :src="require('../../assets/advantages/youshi1-1.png')"
        fit="cover"
        style="
         width: 100%;
         height: auto;
         margin-top: 0rem;
        "
      />
    </view>

  </div>
</template>

<script>

  import {useRouter} from "vue-router";


  export default {
    name: "Advantages",

    setup() {
        const router = useRouter()

        //查看详情
        const handleLookMore = () => {
          router.push(`/advantages`)
        }

        return { handleLookMore }
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: 9rem;
    /*background-color: #F0F0F0;*/
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 0.6rem;

  }

  .titleView{
    width: 100%;
    height: 1.23rem;
    margin-top: 0.37rem;
    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .centerView {
    width: 14.4rem;
    height: 7rem;
    margin-top: 0.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }




</style>
