<template>
  <div class="rootView">

    <!--公司资质-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.80rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/aboutus/us_titel4@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        公司资质
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/aboutus/us_certificate1.jpg')"
      fit="contain"
      style="
        width: 6.99rem;
        height: 4.13rem;
        margin-left: 6.11rem;
        margin-top: 0.40rem;
      "
      lazy
    />


    <!--公司荣誉-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/aboutus/us_titel4@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        公司荣誉
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/aboutus/us_certificate2.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 6.17rem;
        margin-left: 2.50rem;
        margin-top: 0.50rem;
      "
      lazy
    />



    <!--知识产权-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/aboutus/us_titel4@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        知识产权
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/aboutus/us_certificate3.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 2.97rem;
        margin-left: 2.52rem;
        margin-top: 0.40rem;
      "
      lazy
    />


    <!--软件著作权-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/aboutus/us_titel4@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        软件著作权
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/aboutus/us_certificate4_1.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 4.27rem;
        margin-left: 2.50rem;
        margin-top: 0.40rem;
      "
      lazy
    />
    <el-image
      :src="require('../../assets/aboutus/us_certificate4_2.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 4.27rem;
        margin-left: 2.50rem;
        margin-top: 0.40rem;
      "
      lazy
    />
    <el-image
      :src="require('../../assets/aboutus/us_certificate4_3.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 4.27rem;
        margin-left: 2.50rem;
        margin-top: 0.40rem;
      "
      lazy
    />
    <el-image
      :src="require('../../assets/aboutus/us_certificate4_4.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 4.27rem;
        margin-left: 2.50rem;
        margin-top: 0.40rem;
      "
      lazy
    />

    <!--创美建材-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/aboutus/us_titel4@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        创美建材
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/aboutus/us_certificate5.jpg')"
      fit="contain"
      style="
        width: 14.20rem;
        height: 3.30rem;
        margin-left: 2.50rem;
        margin-top: 0.23rem;
      "
      lazy
    />




  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "HonorSubView",

    setup() {

      //const router = useRouter()

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }



</style>
