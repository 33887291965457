<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;"
        src="../../assets/advantages/advantages.png"
        alt />
    </view>

    <!--  图文栏1  -->
    <!--<view class="applyScenePicItemView" style="margin-top: 3.4rem;">
      <view class="applyScenePicItemView_left">
        <el-image
          :src="require('../../assets/cases/project_case_1.png')"
          fit="cover"
          style="
              width: 100%;
              height: 100%;
              position: absolute;
            "
        />
        <text class="applyScenePicItemView_txt">
          方亿科技园
        </text>

      </view>
      <view class="applyScenePicItemView_center">
        <el-image
          :src="require('../../assets/cases/project_case_2.png')"
          fit="cover"
          style="
              width: 100%;
              height: 100%;
              position: absolute;
            "
        />
        <text class="applyScenePicItemView_txt">
          佛山智选假日酒店
        </text>


      </view>
      <view class="applyScenePicItemView_right">
        <el-image
          :src="require('../../assets/cases/project_case_3.png')"
          fit="cover"
          style="
              width: 100%;
              height: 100%;
              position: absolute;
            "
        />
        <text class="applyScenePicItemView_txt">
          安惠大厦
        </text>

      </view>


    </view>-->


    <el-image
      :src="require('../../assets/advantages/youshi1.png')"
      fit="contain"
      style="
        width: 64%;
        height: auto;
         margin-top: 1.2rem;
      "

    />

    <el-image
      :src="require('../../assets/advantages/youshi2.png')"
      fit="contain"
      style="
        width: 64%;
        height: auto;
        margin-top: 1.0rem;
      "

    />



  </div>
</template>


<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "AdvantagesContentView",

    setup() {

      //const router = useRouter()

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    margin-bottom: 1.0rem;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;

    border-radius: 0;

  }


  /*.applyScenePicItemView{
    width: 14.40rem;
    height: 4.00rem;
    margin-left: 3.2rem;

    display: flex;
    flex-direction: row;

    &_left{
      width: 4.00rem;
      height: 4.00rem;

      position: relative;
    }

    &_center{
      width: 4.00rem;
      height: 4.00rem;
      margin-left: 0.5rem;

      position: relative;
    }

    &_right{
      width: 4.00rem;
      height: 4.00rem;
      margin-left: 0.5rem;

      position: relative;
    }

  }*/





</style>
