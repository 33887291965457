<template>
  <!-- 若将arrow设置为always，则会一直显示；设置为never，则会一直隐藏。-->
  <el-carousel :interval="5000" arrow="always" :height="banH +'rem'" >
    <el-carousel-item  v-for="(item, index) in bannerList" :key="index">

      <!--<img class="banner__Pic" :src="item.icon"/> changed 2021.8.6 显示模糊-->

      <!--懒加载  lazy-->
      <el-image
        :src="item.icon"
        fit="contain"
        style="
         width: 100%;
         height: 8.18rem;
      "
      />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
  import {onMounted, ref} from "vue";

  export default {
    name: "Banner",
    setup() {
      const banH = ref(8.18)

      //页面加载后，动态修改view的高度
      onMounted(() => {
        //监听页面事件--resize、click、mousemove...
        window.addEventListener('resize', () => {

          console.log("resize=========")
          banH.value = 8.18;

          //修改 指示点高度  ---无效  todo
         /* const scrollDom = Array.from(document.getElementsByClassName('el-carousel__button'));
          console.log("scrollDom== "+scrollDom)
          let curDom = scrollDom[0];
          console.log("curDom== "+curDom)

          console.log("curDom.style== "+curDom.style)
          console.log("curDom.height== "+curDom.height)
          console.log("curDom.style.height== "+curDom.style.height)


          curDom.style.height = 50+'px'; */


        }, false)

      });

      const bannerList = [
        // {index: '1', icon: require('../../assets/banner/banner1@2x.png')},
        // {index: '2', icon: require('../../assets/banner/banner2@2x.png')}

        {index: '1', icon: require('../../assets/banner/banner1@2x.jpg')},
        {index: '2', icon: require('../../assets/banner/banner2@2x.jpg')}

      ];


      return {bannerList, banH}
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  /*banner*/
  .banner__Pic{
    width: 100%;
    height: 8.18rem;
  }

  /*指示条  不生效*/
  .el-carousel__button{
    height: 0.08rem;
    width: 0.24rem;
  }

</style>
