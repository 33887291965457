<template>
  <div class="rootView">

    <view class="ContentView">

      <!-- 图标区域 -->
      <el-image
        :src="require('../../assets/aboutus/us_pic2@2x.png')"
        fit="cover"
        style="
          width: 12.70rem;
          height: 2.79rem;
          margin-left: 3.25rem;
          margin-top: 0.8rem;
        "
        lazy
      />

      <!--item1  只改left，top即可 -->
      <view class="ContentView_Item"
          style="
            left: 3.72rem;
            top: 4.19rem;
          ">
        <el-image
          :src="require('../../assets/aboutus/lichen_1_plus.png')"
          fit="cover"
          style="
            width: 2.20rem;
            height: 1.50rem;
          "
          lazy
        />

        <text
          style="
            width: 100%;
            height: auto;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            margin-top: 0.22rem;
            line-height: 0.32rem;
          ">
          河北创美科技有限公司成立
        </text>

      </view>


      <!--item2  只改left，top即可 ; top是上一个基础上-60； left是上一个基础上+220+110=330-->
      <view class="ContentView_Item"
            style="
            left: 7.02rem;
            top: 3.59rem;
          ">
        <el-image
          :src="require('../../assets/aboutus/lichen_2_plus_1.png')"
          fit="cover"
          style="
            width: 2.20rem;
            height: 1.50rem;
          "
          lazy
        />

        <text
          style="
            width: 100%;
            height: auto;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            margin-top: 0.22rem;
            line-height: 0.32rem;
          ">
          进军碳钢卡压管道
        </text>

      </view>


      <!--item3  只改left，top即可 ; top是上一个基础上-60 ；left是上一个基础上+220+110=330-->
      <view class="ContentView_Item"
            style="
            left: 10.32rem;
            top: 2.99rem;
          ">
        <el-image
          :src="require('../../assets/aboutus/lichen_3_plus.png')"
          fit="cover"
          style="
            width: 2.20rem;
            height: 1.50rem;
          "
          lazy
        />

        <text
          style="
            width: 110%;
            height: auto;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            margin-top: 0.22rem;
            line-height: 0.32rem;
          ">
          河北创美建材科技有限公司成立
        </text>

      </view>


      <!--item4  只改left，top即可 ; top是上一个基础上-60 ；left是上一个基础上+220+110=330-->
      <view class="ContentView_Item"
            style="
            left: 13.62rem;
            top: 2.39rem;
          ">
        <el-image
          :src="require('../../assets/aboutus/lichen_4_plus.png')"
          fit="cover"
          style="
            width: 2.20rem;
            height: 1.50rem;
          "
          lazy
        />

        <text
          style="
            width: 110%;
            height: auto;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            margin-top: 0.22rem;
            line-height: 0.32rem;
          ">
          深圳美创环境科技有限公司成立、专注于智慧管道直饮水系统研发，提供一体化解决方案
        </text>

      </view>



    </view>

  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "DevelopingProcessSubView",

    setup() {

      //const router = useRouter()



    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .ContentView{
    width: 100%;
    height: 6.2rem;  /* 所有view高度和 671，稍微放大点，后续第一个item文字变高可调整这里 */
    background-color: #FFFFFF;

    position: relative;

    &_Item{

      width: 2.20rem;
      height: auto;

      position: absolute;

      display: flex;
      flex-direction: column;

    }




  }




</style>
