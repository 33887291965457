<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../assets/aboutus/us_bg.jpg"
        alt />
    </view>

    <!--tab 栏-->
    <view class="tabView">

      <view class="tabView_tabItem"
         :class="{'active':tabIndex===1}"
         @click="() => handleTabClick(1)"
         @mouseover="() => handleTabMouseOver(1)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===1"
              :src="require('../../assets/aboutus/us_icon1.1@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon1.2@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
          :style="{color: tabIndex===1?'#FFFFFF':'#333333'}">
          公司简介
        </view>
      </view>

      <view class="tabView_tabItem"
         :class="{'active':tabIndex===2}"
         @click="() => handleTabClick(2)"
         @mouseover="() => handleTabMouseOver(2)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===2"
              :src="require('../../assets/aboutus/us_icon2.2@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon2.1@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===2?'#FFFFFF':'#333333'}">
          发展历程
        </view>
      </view>

      <view class="tabView_tabItem"
         :class="{'active':tabIndex===3}"
         @click="() => handleTabClick(3)"
         @mouseover="() => handleTabMouseOver(3)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===3"
              :src="require('../../assets/aboutus/us_icon3.2@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon3.1@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===3?'#FFFFFF':'#333333'}">
          资质荣誉
        </view>
      </view>

      <view class="tabView_tabItem"
            :class="{'active':tabIndex===4}"
            @click="() => handleTabClick(4)"
            @mouseover="() => handleTabMouseOver(4)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===4"
              :src="require('../../assets/aboutus/us_icon4.2@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon4.1@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===4?'#FFFFFF':'#333333'}">
          企业文化
        </view>
      </view>

      <!--暂时隐藏-->
      <view v-show="false" class="tabView_tabItem"
            :class="{'active':tabIndex===5}"
            @click="() => handleTabClick(5)"
            @mouseover="() => handleTabMouseOver(5)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===5"
              :src="require('../../assets/aboutus/us_icon5.2@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon5.1@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===5?'#FFFFFF':'#333333'}">
          加入创美
        </view>
      </view>

      <view class="tabView_tabItem"
            :class="{'active':tabIndex===6}"
            @click="() => handleTabClick(6)"
            @mouseover="() => handleTabMouseOver(6)">
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===6"
              :src="require('../../assets/aboutus/us_icon6.2@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
          <el-image v-else
              :src="require('../../assets/aboutus/us_icon6.1@2x.png')"
              fit="cover"
              style="
        width: 100%;
        height: 100%;
      "
              lazy
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===6?'#FFFFFF':'#333333'}">
          联系我们
        </view>
      </view>


    </view>


    <!-- 解决方案 动态展示栏   -->
    <component :is="currentView"></component>


  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  import {onMounted, onUnmounted, ref} from 'vue'

  import CompanyInroductionSubView from "./CompanyInroductionSubView";//公司简介
  import DevelopingProcessSubView from "./DevelopingProcessSubView";//发展历程
  import HonorSubView from "./HonorSubView";//资质荣誉
  import EnterpriseCultureSubView from "./EnterpriseCultureSubView";//企业文化
  import JoinUsSubView from "./JoinUsSubView";//加入创美
  import ContactUsSubView from "./ContactUsSubView";//联系我们

  import { useStore } from 'vuex'
  import Bus from "../../service/bus";
  // CompositionAPI 中使用 VueX

  export default {
    name: "AboutUsContentView",
    components: { CompanyInroductionSubView, ContactUsSubView, DevelopingProcessSubView, EnterpriseCultureSubView, HonorSubView, JoinUsSubView },

    //props: ['currentIndex'],

    setup() {


      //const router = useRouter()

      //关于我们，store路由穿透统一控制
      const store = useStore()
      const { aboutusRoute } = store.state;
      let { discard, index} = aboutusRoute;

      onMounted(() => {
        //console.log('onMounted-----------------------------------AboutUs.vue')
        //console.log('discard-----------------------------------'+discard)
        //console.log('index-----------------------------------'+index)

        //关于我们，store路由穿透统一控制
        //handleTabMouseOver(3);
        if(discard === false)
        {
          handleTabMouseOver(index);

          discard = true;
          store.commit('changeAboutusRoute', {discard, index });//更新数据
        }

        /*监听跨组件事件*/
        Bus.$on('changeAboutusRoute', (index) => {
          //console.log(' Bus.$on("product-index", index=>'+index)
          handleTabMouseOver(index);
        })


      });

      onUnmounted(() => {
        //console.log('onUnmounted-----------------------------------Home.vue')

        /*监听跨组件事件*/
        Bus.$off("changeAboutusRoute");

      });

      //tab
      const tabIndex = ref(1)//1.社区住宅 2.商业办公 3.机关单位

      //解决方案  动态组件名
      const currentView = ref('CompanyInroductionSubView')
      const changeCurrentView = (index) => {
        if(index == 1)
        {
          currentView.value = 'CompanyInroductionSubView';
        }
        else if(index == 2)
        {
          currentView.value = 'DevelopingProcessSubView';
        }
        else if(index == 3)
        {
          currentView.value = 'HonorSubView';
        }
        else if(index == 4)
        {
          currentView.value = 'EnterpriseCultureSubView';
        }
        else if(index == 5)
        {
          currentView.value = 'JoinUsSubView';
        }
        else if(index == 6)
        {
          currentView.value = 'ContactUsSubView';
        }



      }

      //初始化
      //changeCurrentView('${currentIndex}');

      //tab点击函数定义
      const handleTabClick = (index) => {

        console.log("handleTabClick=======> "+index);
        tabIndex.value = index;

        //changeCurrentView(index);

      }


      //tab鼠标上移函数定义
      const handleTabMouseOver = (index) => {
        console.log("handleTabMouseOver=======> "+index);
        tabIndex.value = index;

        changeCurrentView(index);
      }







      return { handleTabClick, handleTabMouseOver, tabIndex, currentView }
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/

    border-radius: 0;
  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }

  /*tab 栏*/
  .tabView{
    width: 100%;
    height: 0.70rem;
    background: #F5F5F5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &_tabItem{
      width: 2.20rem;
      height: 0.70rem;
      background: #F5F5F5;

      margin-left: 0.1rem;
      margin-right: 0.1rem;

      position: relative;

    }



  }

  .tabView_tabItem_img{
    width: 0.18rem;
    height: 0.18rem;

    position: absolute;
    left: 0.34rem;
    top:0.28rem;
  }

  .tabView_tabItem_txt{

    width: 0.80rem;
    height: 0.18rem;

    position: absolute;
    left: 0.81rem;
    top:0.26rem;

    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

  }

  /*选中设备类型后的，按钮激活效果*/
  .active{
    background: #3399FF;
    /*color: white;*/
  }


</style>
