<template>
  <div class="rootView">

    <view class="ContentView">

      <!-- 左边文字栏 -->
      <view class="ContentView_left">
<!--
       <text
         style="
         width: 100%;
         height: auto;
         font-size: 0.16rem;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #000000;

         margin-left: 0rem;
         margin-top: 0rem;
         line-height: 0.32rem;

           ">
         &emsp;&emsp;河北创美集团旗下拥有河北创美科技有限公司、河北创美建材科技有限公司、深圳美创环境科技有限公司等，业务涉及工程施工、建材产品销售和民生互联网产品研发等多个领域，是集消防工程设计施工、高科技建材销售和民生互联网产品研发于一体的外向型民营企业。
       </text>

       <text
         style="
         width: 100%;
         height: auto;
         font-size: 0.16rem;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #000000;

         margin-left: 0rem;
         margin-top: 0.32rem;
         line-height: 0.32rem;

           ">
         &emsp;&emsp;公司自2020年4月起，专注致力于智慧管道直饮水的推广，水处理技术，工业自动化设备及产品的系统设计、研发制造，拥有多项自主知识产权，将智能设备、应用管理软件与物联网云服务相结合，为客户提供智慧管道直饮水系统解决方案。
       </text>

       <text
         style="
         width: 100%;
         height: auto;
         font-size: 0.16rem;
         font-family: Microsoft YaHei;
         font-weight: 400;
         color: #000000;

         margin-left: 0rem;
         margin-top: 0.32rem;
         line-height: 0.32rem;

           ">
         &emsp;&emsp;“为全民健康生活提供优质的产品和服务”是全体创美人的共同使命和矢志不渝的追求，创美集团秉持“服务至上”的经营理念，以优秀的服务团队，先进的技术，完善的售后保障体系，持续赢得客户信赖，期待与国内外有识之士真诚合作，携手共创美好未来。
       </text>
 -->

       <text
         style="
           width: 100%;
           height: auto;
           font-size: 0.16rem;
           font-family: Microsoft YaHei;
           font-weight: 400;
           color: #000000;

           margin-left: 0rem;
           margin-top: 0.0rem;
           line-height: 0.32rem;

           ">
         &emsp;&emsp;河北创美集团成立于2009年10月，是集消防工程设计施工、高科技建材销售和民生互联网产品研发于一体的外向型民营企业。自2020年4月起，专注致力于智慧管道直饮水的推广，水处理技术，工业自动化设备及产品的系统设计、研发制造，目前产品已遍及食品、饮料、电子、医疗、制药、生物工程等行业和领域。是中国饮料工业协会会员、中国膜工业协会会员、国际脱盐协会中国分会会员；GE华南地区一等供应商、中国UV协会会员，中国自动识别技术协会、中科院自动化所自由软件协会会员。在饮用矿泉水、直饮水、矿物质水设备工程、线体组装工程等方面，以高新科技塑造企业超凡实力,多渠道与国内外知名企业进行广泛的交流与合作,以技术的不断创新来满足更高标准和集成系统的需要。
       </text>

       <text
         style="
           width: 100%;
           height: auto;
           font-size: 0.16rem;
           font-family: Microsoft YaHei;
           font-weight: 400;
           color: #000000;

           margin-left: 0rem;
           margin-top: 0.32rem;
           line-height: 0.32rem;

           ">
          &emsp;&emsp;“为全民健康生活提供优质的产品和服务”是全体创美人的共同使命和矢志不渝的追求，创美集团秉持“服务至上”的经营理念，以优秀的服务团队，先进的技术，完善的售后保障体系，持续赢得客户信赖，期待与国内外有识之士真诚合作，携手共创美好未来。
       </text>


     </view>

     <!-- 右边图片栏 -->
      <view class="ContentView_right">
        <el-image
          :src="require('../../assets/aboutus/us_bg2@2x.png')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>

    </view>

  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "CompanyInroductionSubView",

    setup() {

      //const router = useRouter()



    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .ContentView{
    width: 100%;
    height: 4.8rem;
    background-color: #FFFFFF;

    position: relative;

    &_left{
      width: 6.98rem;
      height: 4.0rem;

      position: absolute;
      left: 2.39rem;
      top:0.79rem;

      display: flex;
      flex-direction: column;
    }

    &_right{
      width: 7.0rem;
      height: 4.0rem;

      position: absolute;
      left: 9.80rem;/*239+698+43*/
      top:0.79rem;

    }


  }




</style>
