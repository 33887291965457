<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../assets/news/news_pic.jpg"
        alt />
    </view>

    <!--tab 栏-->
    <view class="tabView">

      <!-- 去掉 @mouseleave="() => handleTabMouseOver(0)"，不然鼠标离开，没一个能选中了-->
      <view class="tabView_tabItem"
         :class="{'active':tabIndex===1}"
         @click="() => handleTabClick(1)"
         @mouseover="() => handleTabMouseOver(1)"
      >
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===1"
            :src="require('../../assets/news/news_icon1.1@2x.png')"
            fit="cover"
            style="
              width: 100%;
              height: 100%;
            "
          />
          <el-image v-else
              :src="require('../../assets/news/news_icon1.2@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
          />
        </view>

        <view class="tabView_tabItem_txt"
          :style="{color: tabIndex===1?'#FFFFFF':'#333333'}">
          行业新闻
        </view>


      </view>

      <view class="tabView_tabItem"
         :class="{'active':tabIndex===2}"
         @click="() => handleTabClick(2)"
         @mouseover="() => handleTabMouseOver(2)"


      >
        <view class="tabView_tabItem_img">
          <el-image v-if="tabIndex===2"
              :src="require('../../assets/news/news_icon2.2@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
          />
          <el-image v-else
              :src="require('../../assets/news/news_icon2.1@2x.png')"
              fit="cover"
              style="
              width: 100%;
              height: 100%;
            "
          />
        </view>

        <view class="tabView_tabItem_txt"
              :style="{color: tabIndex===2?'#FFFFFF':'#333333'}">
          产品资讯
        </view>

      </view>


    </view>


    <!-- 解决方案 动态展示栏   -->
    <component :is="currentView"></component>


  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  import {onMounted, onUnmounted, ref} from 'vue'

  import News_1 from "./News_1";
  import News_2 from "./News_2";
  import {useStore} from "vuex";
  import Bus from "../../service/bus";



  export default {
    name: "NewsContentView",
    components: {News_1, News_2},

    setup() {

      //const router = useRouter()
      //tab
      const tabIndex = ref(1)//1.社区住宅 2.商业办公 3.机关单位

      //解决方案  动态组件名
      const currentView = ref('News_1')
      const changeCurrentView = (index) => {
        if(index == 1)
        {
          currentView.value = 'News_1';
        }
        else if(index == 2)
        {
          currentView.value = 'News_2';
        }

      }

      //tab点击函数定义
      const handleTabClick = (index) => {

        console.log("handleTabClick=======> "+index);
        tabIndex.value = index;

        //changeCurrentView(index);

      }


      //tab鼠标上移函数定义
      const handleTabMouseOver = (index) => {
        console.log("handleTabMouseOver=======> "+index);
        tabIndex.value = index;

        changeCurrentView(index);
      }


      //新闻资讯，store路由穿透统一控制
      const store = useStore()
      const { newsRoute } = store.state;
      let { discard, index} = newsRoute;
      onMounted(() => {
        //console.log('onMounted-----------------------------------NewsContentView.vue')

        //解决方案，store路由穿透统一控制
        if(discard === false)
        {
          handleTabMouseOver(index);

          discard = true;
          store.commit('changeNewsRouteRoute', {discard, index });//更新数据
        }

        /*监听跨组件事件*/
        Bus.$on('changeNewsRouteRoute', (index) => {
          //console.log(' Bus.$on("product-index", index=>'+index)
          handleTabMouseOver(index);
        })


      });

      onUnmounted(() => {
        //console.log('onUnmounted-----------------------------------Home.vue')

        /*监听跨组件事件*/
        Bus.$off("changeNewsRouteRoute");

      });


      return { handleTabClick,handleTabMouseOver, tabIndex, currentView }
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/


    border-radius: 0;
  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }

  /*tab 栏*/
  .tabView{
    width: 100%;
    height: 0.70rem;
    background: #F5F5F5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &_tabItem{
      width: 2.20rem;
      height: 0.70rem;
      background: #F5F5F5;

      margin-left: 0.1rem;
      margin-right: 0.1rem;

      position: relative;

    }



  }

  .tabView_tabItem_img{
    width: 0.18rem;
    height: 0.18rem;

    position: absolute;
    left: 0.34rem;
    top:0.26rem;
  }

  .tabView_tabItem_txt{

    width: 0.80rem;
    height: 0.18rem;

    position: absolute;
    left: 0.81rem;
    top:0.26rem;

    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;

  }

  /*选中设备类型后的，按钮激活效果*/
  .active{
    background: #3399FF;
    /*color: white;*/
  }


</style>
