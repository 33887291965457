<template>
  <div class="wrapper">

    <!-- 顶部导航 -->
    <Header :currentIndex="1"/>

    <view class="topView">

      <!-- 标题栏 -->
      <view class="topView__titleView">
        <img
          style="width: 100%; height: 2.6rem;position: absolute;"
          src="../../assets/product/product_banner@2x.jpg"
          alt />
      </view>

      <!-- tab按钮   -->
      <view class="topView__tabView">
        <!--   动态绑定css   :class="{'active':isActive==-1}-->
        <!--   @mouseleave="() => handleTabMouseOver(0)"-->
        <el-button :class="{'topView__tabView_tab1':true, 'active':devType==1}"
                   type="primary" plain round
                   @click="() => handleTabClick(1)"
                   @mouseover="() => handleTabMouseOver(1)">
          智能一体式设备
        </el-button>
        <el-button :class="{'topView__tabView_tab2':true, 'active':devType==2}"
                   type="primary" plain round
                   @click="() => handleTabClick(2)"
                   @mouseover="() => handleTabMouseOver(2)">
          设备组件
        </el-button>
        <el-button :class="{'topView__tabView_tab3':true, 'active':devType==3}"
                   type="primary" plain round
                   @click="() => handleTabClick(3)"
                   @mouseover="() => handleTabMouseOver(3)">
          饮水终端
        </el-button>
        <el-button :class="{'topView__tabView_tab4':true, 'active':devType==4}"
                   type="primary" plain round
                   @click="() => handleTabClick(4)"
                   @mouseover="() => handleTabMouseOver(4)">
          直饮水机
        </el-button>
      </view>


    </view>


    <!--<ProductListView/>-->
    <!-- 产品列表 动态展示栏   -->
    <component :is="currentView"></component>


    <!-- 底部栏1 -->
    <Footer @backTop="backTop()" @showMsgDialog="showMsgDialog(true)"/>

  </div>

  <!--  留言版  -->
  <div v-if="isShowMsgDialog" class="leave-msg-dialog">
    <MessageBoard @closeMsgDialog="showMsgDialog(false)"/>
  </div>

</template>



<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  //产品列表
  //1、智能一体设备
  import ProductListView1 from "./ProductListView1";
  //2、设备组件
  import ProductListView2 from "./ProductListView2";
  //3、饮水终端
  import ProductListView3 from "./ProductListView3";
  //4、商用直饮水机
  import ProductListView4 from "./ProductListView4";


  // 回到顶部
  import { onMounted, onUnmounted } from 'vue'
  import backToTopEffect from '../../effects/backToTopEffect'

  //留言
  import { ref } from 'vue'
  import MessageBoard from "../../components/MessageBoard";

  import {useStore} from "vuex";

  //跨组件，事件传递
  import Bus from "../../service/bus";

  export default {

    name: 'Products',
    components: {Header, Footer,  MessageBoard, ProductListView1, ProductListView2, ProductListView3, ProductListView4},


    setup() {

      //回到顶部
      const { addLisener, removeLisener, backTop } = backToTopEffect();

      //产品中心，store路由穿透统一控制
       const store = useStore()
      const { productsRoute } = store.state;
      let { discard, index} = productsRoute;

      onMounted(() => {
        //console.log('onMounted-----------------------------------Home.vue')

        // 回到顶部 滚动条所在节点，添加监听
        const scrollDom = Array.from(document.getElementsByClassName('wrapper'));
        addLisener(scrollDom)

        //产品中心，store路由穿透统一控制
        if(discard === false)
        {
          handleTabMouseOver(index);

          discard = true;
          store.commit('changeProductsRoute', {discard, index });//更新数据
        }

        /*监听跨组件事件*/
        Bus.$on('changeProductsRoute', (index) => {
          //console.log(' Bus.$on("product-index", index=>'+index)
          handleTabMouseOver(index);
        })


      });

      onUnmounted(() => {
        //console.log('onUnmounted-----------------------------------Home.vue')

        //回到顶部 滚动条所在节点，移除监听
        removeLisener();

        /*监听跨组件事件*/
        Bus.$off("changeProductsRoute");

      });


      //留言
      const isShowMsgDialog = ref(false)//是否显示留言板
      const showMsgDialog = (showit) => {
        isShowMsgDialog.value = showit;
      }


      //产品列表  动态组件名
      const currentView = ref('ProductListView1')
      const changeCurrentView = (index) => {
        if(index == 1)
        {
          currentView.value = 'ProductListView1';
        }
        else if(index == 2)
        {
          currentView.value = 'ProductListView2';
        }
        else if(index == 3)
        {
          currentView.value = 'ProductListView3';
        }
        else if(index == 4)
        {
          currentView.value = 'ProductListView4';
        }


      }


      const devType = ref(1);//1、智能一体式设备  2、设备组件  3、饮水终端  4、直饮水机

      //tab点击函数定义
      const handleTabClick = (index) => {

        console.log("handleTabClick=======> "+index);
        devType.value = index;

        //changeCurrentView(index);

      }


      //tab鼠标上移函数定义
      const handleTabMouseOver = (index) => {
        console.log("handleTabMouseOver=======> "+index);
        devType.value = index;

        changeCurrentView(index);
      }



      return {backTop , isShowMsgDialog, showMsgDialog, handleTabClick, handleTabMouseOver, currentView, devType}


    }



  }
</script>



<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .wrapper {
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0 .0rem .0rem .0rem;
  }


  .topView{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    &__titleView{
      width: 100%;
      height: 2.60rem;
      /*background-color: red;*/
    }

    &__tabView{
      width: 100%;
      height: 0.48rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 0.2rem;

      &_tab1 {
        width: 1.86rem;
        height: 100%;
      }

      &_tab2 {
        width: 1.86rem;
        height: 100%;
        margin-left: 0.2rem;
      }

      &_tab3 {
        width: 1.86rem;
        height: 100%;
        margin-left: 0.2rem;
      }

      &_tab4 {
        width: 1.86rem;
        height: 100%;
        margin-left: 0.2rem;
      }

    }


  }


  /*选中设备类型后的，按钮激活效果*/
  .active{
    background: #3399FF;
    color: white;
  }



</style>
