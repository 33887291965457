<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../assets/product/product_banner@2x.jpg"
        alt />
    </view>

    <!--面包屑-->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <el-breadcrumb separator="》"
      style="
        width: auto;
        height: 0.15rem;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    >
      <el-breadcrumb-item :to="{ path: '/' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          首页
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          产品中心
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          智能一体化设备（标准版）
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #45A2FF
          "
        >
          产品详情
        </text>

      </el-breadcrumb-item>
    </el-breadcrumb>

    <!--横线-->
    <view
      style="
        width: 14.4rem;
        height: 0.01rem;
        background: #CCCCCC;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    />

    <!--简介-->
    <view class="introduceView">

      <!--  以下从首页拷贝过来    -->
      <view class="devView_item_bottom">

        <!--   设备图    670-28-28=614   340-28-28=284-->
        <el-image
          :src="require('../../assets/home/product/product1@2x.png')"
          fit="contain"
          style="
              width: 7.10rem;
              height: 4rem;
              background: #F0F6FC;

              position:absolute;
              top: 0;
              left: 0rem;

              padding: 0rem;

            "
        />

        <view class="devView_item_bottom_right">

          <text
            style="
                width: auto;
                height: 0.2rem;
                font-size: 0.2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;


                margin-top: 0.68rem;
                margin-left: 0.71rem;

            ">
            智能一体化设备（标准版）
          </text>

          <text
            style="
                width: 6.0rem;
                height: 1.1rem;
                font-size: 0.16rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.52rem;
                opacity: 0.7;

                margin-top: 0.29rem;
                margin-left: 0.71rem;
                margin-right: 0.60rem;

            ">
            设备采用目前前沿工艺制造，具有高度集成化、安全性高、故障率低、维护维修便捷、安装便捷、占地面积小等优异特点，根据具体场景需求定制个性化方案。
          </text>

          <el-button type="info"
              @click="handleLookDetail()"
              style="
                width: 1.54rem;
                height: 0.48rem;
                background: #BBBBBB;
                border-radius: 0.24rem;

                font-size: 0.18rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;

                margin-top: 0.51rem;
                margin-left: 0.71rem;
            ">
            查看详情》
          </el-button>


        </view>






      </view>

    </view>

    <!-- 设计标准-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.34rem">
      <el-image class="itemTitle_img"
        :src="require('../../assets/product/product_title@2x.png')"
        fit="contain"
        lazy
      />
      <text class="itemTitle_txt">
        设计标准
      </text>
    </view>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.39rem;

            ">
      整线设计按美国NSF、 FDA 标准，采用反渗透及臭氧紫外线复合杀菌循环系统，100%无卫生死角。

    </text>

    <!-- 图片-->
    <view class="designStandardPicview">
      <view class="designStandardPicview_left">
        <el-image
          :src="require('../../assets/product/fda@2x.png')"
          fit="contain"
          style="
              width: 100%;
              height: 100%;
            "
          lazy
        />

      </view>
      <view class="designStandardPicview_right">
        <el-image
          :src="require('../../assets/product/nsf@2x.png')"
          fit="contain"
          style="
              width: 100%;
              height: 100%;
            "
          lazy
        />

      </view>
    </view>


    <!-- 智慧管理平台-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/product/product_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        智慧管理平台
      </text>
    </view>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.39rem;

            ">
      24小时设备监测、滤芯更换及故障预警、远程开关控制、费用统计及结算  （智慧云平台），在线查看水质、掌上充值、用水查询、报修服务（APP用户）。

    </text>

    <!-- 图标区域 -->
    <view class="intelligentManager">
      <el-image
        :src="require('../../assets/product/icon.png')"
        fit="contain"
        style="
              width: 100%;
              height: 100%;
            "
        lazy
      />
    </view>


    <!-- 全自动智能控制系统   -->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.73rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/product/product_title@2x.png')"
          fit="cover"
          lazy
      />
      <text class="itemTitle_txt">
        全自动智能控制系统
      </text>
    </view>
    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.40rem;

            ">
      设备自动开启、定时自动冲洗。

    </text>

    <!-- 图片-->
    <view class="intelligentControlSystemView">
<!--      <view class="intelligentControlSystemView_left">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic2@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--            "-->
<!--          lazy-->
<!--        />-->

<!--      </view>-->
<!--      <view class="intelligentControlSystemView_right">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic3@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--            "-->
<!--          lazy-->
<!--        />-->

<!--      </view>-->
      <el-image
        :src="require('../../assets/product/product_pic2.jpg')"
        fit="contain"
        style="
              width: 100%;
              height: 100%;
            "
        lazy
      />
    </view>


    <!-- 进口RO反渗透过滤净化技术-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.74rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/product/product_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        进口RO反渗透过滤净化技术
      </text>
    </view>

    <!-- 图片   -->
    <el-image
      :src="require('../../assets/product/product_pic4@2x.png')"
      fit="contain"
      style="
          width: 14.40rem;
          height: 4.55rem;
          margin-left: 2.4rem;
          margin-top: 0.2rem;
        "
      lazy
    />


    <!-- 臭氧杀菌和紫外线复合杀菌系统及终端微滤装置   -->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.54rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/product/product_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        臭氧杀菌和紫外线复合杀菌系统及终端微滤装置
      </text>
    </view>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.40rem;

            ">
      复合杀菌，抑制细菌微生物滋生，保障终端用水的品质和安全。

    </text>

    <!-- 图片 1  -->
    <el-image
      :src="require('../../assets/product/product_pic12.png')"
      fit="contain"
      style="
          width: 14.40rem;
          height: 8.20rem;
          margin-left: 2.4rem;
          margin-top: -0.7rem;
        "
      lazy
    />

    <!-- 图片 2  -->
    <el-image
      :src="require('../../assets/product/product_pic5@2x.png')"
      fit="contain"
      style="
          width: 14.40rem;
          height: 7.01rem;
          margin-left: 2.4rem;
          margin-top: -1.4rem;
        "
      lazy
    />


    <!-- 应用场景   -->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
<!--    <view class="itemTitle"  2021.8.9修改-->
<!--          style="margin-top: 0.74rem"> -->
    <view class="itemTitle"
          style="margin-top: 0.0rem">
    <el-image class="itemTitle_img"
          :src="require('../../assets/product/product_title@2x.png')"
          fit="cover"
          lazy
      />
      <text class="itemTitle_txt">
        应用场景
      </text>
    </view>

    <!--  图文栏1  -->
<!--    <view class="applyScenePicItemView" style="margin-top: 0.41rem;">-->
<!--      <view class="applyScenePicItemView_left">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic6@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          企事业单位-->
<!--        </text>-->

<!--      </view>-->
<!--      <view class="applyScenePicItemView_center">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic7@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          商圈写字楼-->
<!--        </text>-->


<!--      </view>-->
<!--      <view class="applyScenePicItemView_right">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic8@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          住宅小区-->
<!--        </text>-->

<!--      </view>-->


<!--    </view>-->

    <!--  图文栏2  -->
<!--    <view class="applyScenePicItemView" style="margin-top: 0.2rem;">-->
<!--      <view class="applyScenePicItemView_left">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic9@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--          lazy-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          学校-->
<!--        </text>-->

<!--      </view>-->
<!--      <view class="applyScenePicItemView_center">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic10@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--          lazy-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          酒店-->
<!--        </text>-->


<!--      </view>-->
<!--      <view class="applyScenePicItemView_right">-->
<!--        <el-image-->
<!--          :src="require('../../assets/product/product_pic11@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--              width: 100%;-->
<!--              height: 100%;-->
<!--              position: absolute;-->
<!--            "-->
<!--          lazy-->
<!--        />-->
<!--        <text class="applyScenePicItemView_txt">-->
<!--          工厂园区-->
<!--        </text>-->

<!--      </view>-->


<!--    </view>-->

    <el-image
      :src="require('../../assets/product/product_pic13.jpg')"
      fit="contain"
      style="
          width: 14.20rem;
          height: 5.60rem;
          margin-left: 2.5rem;
          margin-top: 0.41rem;
        "
      lazy
    />



  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "Product_1",

    setup() {
      const router = useRouter()


      //查看详情
      const handleLookDetail = () => {
        router.push(`/solutions`)
      }


      return {handleLookDetail}
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/


    border-radius: 0;
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }

  /*简介*/
  .introduceView{
    width: 14.40rem;
    height: 4rem;
    margin-left: 2.4rem;

    margin-top: 0.4rem;

    /*background-color: red;*/
  }

  .devView_item_bottom{
    width: 100%;
    height: 100%;

    position: relative;

    /*670+50=720*/
    &_right{
      width: 7.10rem;
      height: 4rem;
      background: #F0F0F0;

      position: absolute;
      top:0;
      left: 7.30rem;

      display: flex;
      flex-direction: column;

    }

  }

  /* 设计标准 */
  .designStandardPicview{
    width: 14.40rem;
    height: 1.78rem;
    margin-left: 2.4rem;

    margin-top: 0.41rem;

    position: relative;

    &_left{
      width: 7.10rem;
      height: 1.78rem;

      position: absolute;
      left: 0;
      top:0;

      /*background-color: red;*/
    }

    &_right{
      width: 7.10rem;
      height: 1.78rem;

      position: absolute;
      right: 0;
      top:0;

      /*background-color: blue;*/
    }

  }


  /* 智慧管理 */
  .intelligentManager{
    width: 14.40rem;
    height: 1.60rem;
    margin-left: 2.05rem;  /* 2.4 +1.29 */

    margin-top: 0.59rem;
  }

  /* 智能控制系统 */
  .intelligentControlSystemView{
    width: 14.40rem;
    height: 2.377rem;
    margin-left: 2.05rem;

    margin-top: 0.4rem;

    position: relative;

    &_left{
      width: 7.10rem;
      height: 2.377rem;

      position: absolute;
      left: 0;
      top:0;

      /*background-color: red;*/
    }

    &_right{
      width: 7.10rem;
      height: 2.377rem;

      position: absolute;
      right: 0;
      top:0;

      /*background-color: blue;*/
    }

  }


  /* 应用场景 */
  .applyScenePicItemView{
    width: 14.40rem;
    height: 4.60rem;
    margin-left: 2.4rem;

    display: flex;
    flex-direction: row;

    &_left{
      width: 4.60rem;
      height: 4.60rem;

      position: relative;
    }

    &_center{
      width: 4.60rem;
      height: 4.60rem;
      margin-left: 0.2rem;

      position: relative;
    }

    &_right{
      width: 4.60rem;
      height: 4.60rem;
      margin-left: 0.2rem;

      position: relative;
    }

  }
  .applyScenePicItemView_txt{
    width: 100%;
    height: 0.4rem;

    background: #000000;
    opacity: 0.6;

    position: absolute;
    left: 0;
    bottom: 0;

    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;

    line-height: 0.4rem;

  }



</style>
