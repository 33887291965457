<template>
  <div class="rootView">

    <!-- 简介 -->
    <text
      style="
          width: 14.41rem;
          height: auto;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.39rem;
          margin-top: 0.79rem;
          line-height: 0.32rem;

            ">
      &emsp;&emsp; 创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。

    </text>

    <text
      style="
          width: 14.41rem;
          height: auto;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.39rem;
          margin-top: 0.40rem;
          line-height: 0.32rem;

            ">
      &emsp;&emsp; 运用物联网技术实现掌上充值、费用自动结算、数据实时采集、远程控制、会员服务、故障预警等功能数据化、可视化管理。

    </text>


    <!--1.系统组成-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.73rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        1.系统组成
      </text>
    </view>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.39rem;
          line-height: 0.32rem;

            ">
      &emsp;&emsp;建立中央机房，中央直饮水机+食品卫生级不锈钢循环管网+NB-IOT智能水表+饮水终端+智慧服务平台。

    </text>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic1.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 5.20rem;
        margin-left: 2.4rem;
        margin-top: 0.38rem;
      "
      lazy
    />


    <!--2.系统优势-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.72rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        2.系统优势
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic2.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 4.05rem;
        margin-left: 2.4rem;
        margin-top: 0.4rem;
      "
      lazy
    />

    <!--3.系统工艺-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.72rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        3.系统工艺
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic3.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 8.0rem;
        margin-left: 2.4rem;
        margin-top: 0.4rem;
      "
      lazy
    />


    <!--4.优势对比-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.73rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        4.优势对比
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic4.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 6.50rem;
        margin-left: 2.4rem;
        margin-top: 0.4rem;
      "
      lazy
    />


    <!--5.智慧云平台-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.73rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        5.智慧云平台
      </text>
    </view>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic5.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 6.0rem;
        margin-left: 2.4rem;
        margin-top: 0.4rem;
      "
      lazy
    />


    <!--6.服务平台-->
    <!-- 标题栏        --只要改 style="margin-top: 0.34rem",和文字 就行 -->
    <view class="itemTitle"
          style="margin-top: 0.73rem">
      <el-image class="itemTitle_img"
          :src="require('../../assets/solutions/solution_title@2x.png')"
          fit="contain"
          lazy
      />
      <text class="itemTitle_txt">
        6.服务平台
      </text>
    </view>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.20rem;
          font-size: 0.20rem;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #1990FF;

          margin-left: 2.4rem;
          margin-top: 0.4rem;
          line-height: 0.32rem;


            ">
      APP、小程序

    </text>

    <!-- 文字介绍-->
    <text
      style="
          width: auto;
          height: 0.17rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;

          margin-left: 2.4rem;
          margin-top: 0.39rem;
          line-height: 0.32rem;

            ">
      &emsp;&emsp;自助开户、充值、订单查询、用水统计、个人中心、客服服务等，为用户提供一站式线上服务平台。

    </text>

    <!-- 图标区域 -->
    <el-image
      :src="require('../../assets/solutions/solution_pic6.jpg')"
      fit="contain"
      style="
        width: 14.40rem;
        height: 5.16rem;
        margin-left: 2.4rem;
        margin-top: 0.39rem;
      "
      lazy
    />



  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "Solutions_1",

    setup() {

      //const router = useRouter()

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.27rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.83rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }



</style>
