<template>
  <div class="rootView">

    <view class="ContentView">

      <!-- 图标区域 -->
      <el-image
        :src="require('../../assets/aboutus/us_pic3@2x.png')"
        fit="cover"
        style="
          width: 14.41rem;
          height: 4.98rem;

          position: absolute;
        "
        lazy
      />

      <!--item1  只改top即可 -->
      <view class="ContentView_Item"
            style="
              top: 0.54rem;
            ">
        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3399FF;
            line-height: 0.6rem;
          ">
          企业愿景：
        </text>

        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            color: #333333;
            line-height: 0.6rem;
          ">
          创造美好生活。
        </text>

      </view>

      <!--item2  只改top即可 -->
      <view class="ContentView_Item"
            style="
              top: 1.14rem;
            ">
        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3399FF;
            line-height: 0.6rem;
          ">
          企业使命：
        </text>

        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            color: #333333;
            line-height: 0.6rem;
          ">
          为全民健康生活提供优质的产品和服务。
        </text>

      </view>

      <!--item3  只改top即可 -->
      <view class="ContentView_Item"
            style="
              top: 1.74rem;
            ">
        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #3399FF;
            line-height: 0.6rem;
          ">
          企业价值观：
        </text>

        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            color: #333333;
            line-height: 0.6rem;
          ">
          服务至上；榕树精神；重责任重使命；有理想有思想。
        </text>

      </view>



    </view>

  </div>
</template>

<script>
  //import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "EnterpriseCultureSubView",

    setup() {

      //const router = useRouter()



    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .ContentView{
    width: 14.41rem;
    height: 4.98rem;  /* 所有view高度和*/
    background-color: #FFFFFF;
    margin-left: 2.39rem;
    margin-top: 0.80rem;

    position: relative;

    &_Item{

      width: auto;
      height: auto;

      position: absolute;
      left: 0.9rem;

      display: flex;
      flex-direction: row;

    }




  }




</style>
