<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 2.52rem; height: 0.1rem;position: absolute;"
        src="../../assets/home/title_bg@2x.png"
        alt />

      <text
        style="
          width: 1.12rem;
          height: 0.29rem;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
        ">
        新闻资讯
      </text>
    </view>

    <!-- 中间栏 -->
    <view class="centerView">

      <view class="centerView__left"
          :class="{'news_item':true, 'active':itemPositon== 1}"
          @mouseover="() => handleItemMouseOver(1)"
          @click="() => handleItemClick(1)"
      >
        <text     v-show="false"
          style="
              width: 100%;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.15rem;

              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

            ">
          2021-08-16
        </text>

        <el-image
          :src="require('../../assets/news/news-2.png')"
          fit="cover"
          style="
            width: 4.36rem;
            margin-top: 0.15rem;
            height: 3rem;
            margin-left: 0.15rem;
          "
        />

        <text
          style="
              width: 100%;
              height: auto;
              margin-left: 0.15rem;
              /* margin-top: 0.2rem; */
              font-size: 0.2rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0.55rem;
              color: rgb(0, 0, 0);
              font-size: 0.18rem;

            ">
          为什么选择管道直饮水？
        </text>

        <text     v-show="false"
          style="
              width: 5.0rem;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.20rem;


              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4D4D4D;
              line-height: 0.28rem;

            ">
          &emsp; &emsp;管道直饮水，兴起于90年代中期，由中央净水设备在自来水提供的原水基础上经过再净化过程提供给人们饮用。 我们为什么要选择管道直饮水？ 它相对于传统饮用水方式，有哪些优点呢？
        </text>


      </view>

      <view class="centerView__center"
            :class="{'news_item':true, 'active':itemPositon== 2}"
            @mouseover="() => handleItemMouseOver(2)"
            @click="() => handleItemClick(2)"
      >
        <text
          v-show="false"
          style="
              width: 100%;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.15rem;

              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

            ">
          2021-08-16
        </text>

        <el-image
          :src="require('../../assets/news/news-1.png')"
          fit="cover"
          style="
            width: 4.36rem;
            margin-top: 0.15rem;
            height: 3rem;
            margin-left: 0.15rem;
          "
        />

        <text
          style="
              width: 100%;
              height: auto;
              margin-left: 0.15rem;
              /* margin-top: 0.2rem; */
              font-size: 0.2rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0.55rem;
              color: rgb(0, 0, 0);
              font-size: 0.18rem;

            ">
          创美智慧管道直饮水开启健康饮水新时代
        </text>

        <text v-show="false"
              style="
              width: 5.0rem;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.20rem;


              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4D4D4D;
              line-height: 0.28rem;

            ">
          &emsp; &emsp;   创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
        </text>

      </view>

      <view class="centerView__right"
          :class="{'news_item':true, 'active':itemPositon== 3}"
          @mouseover="() => handleItemMouseOver(3)"
          @click="() => handleItemClick(3)"
      >
        <text
          v-show="false"
          style="
              width: 100%;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.15rem;

              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;

            ">
          2021-08-16
        </text>

        <el-image
          :src="require('../../assets/home/product/product_clener_biaozhun.png')"
          fit="cover"
          style="
              width: 4.36rem;
              margin-top: 0.15rem;
              height: 3rem;
              margin-left: 0.15rem;
          "
        />

        <text
          style="
              width: 100%;
              height: auto;
              margin-left: 0.15rem;
              /* margin-top: 0.2rem; */
              font-size: 0.2rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 0.55rem;
              color: rgb(0, 0, 0);
              font-size: 0.18rem;

            ">
          创美中央直饮水系统工艺流程
        </text>

        <text v-show="false"
          style="
              width: 5.0rem;
              height: auto;

              margin-left: 0.3rem;
              margin-top: 0.20rem;


              font-size: 0.14rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4D4D4D;
              line-height: 0.28rem;

            ">
          &emsp; &emsp;   创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
        </text>

      </view>

    </view>

    <el-button type="info"
       @click="handleLookDetail()"
       style="
          width: 1.50rem;
          height: 0.5rem;
          background: rgb(187, 187, 187);
          border-radius: 0.24rem;
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgb(255, 255, 255);
          margin-top: 0.4rem;
          margin-left: 0rem;
          margin-bottom: 0.5rem;
       ">
      查看更多》
    </el-button>

  </div>
</template>

<script>
    import {useRouter} from "vue-router";
    import {ref} from "vue";

    export default {
      name: "NewsInfo",

      setup() {

        const router = useRouter()

        const itemPositon = ref('1')//tag编号

        //item点击函数定义
        const handleItemClick = (itemId) => {
          console.log("handleItemClick=======> itemId: "+itemId);

          /*新闻 item点击*/
          if(itemId == 1){
            router.push(`/news_hangye_detail_1`)
          }
          else if(itemId == 2){
            router.push(`/news_chanpin_detail_1`)
          }
          else if(itemId == 3){
            router.push(`/news_chanpin_detail_2`)
          }

        }

        //item鼠标上移函数定义
        const handleItemMouseOver = (positon) => {
          itemPositon.value = positon;
        }

        //查看详情
        const handleLookDetail = () => {
          router.push(`/news`)
        }

        return { handleItemClick, itemPositon, handleItemMouseOver, handleLookDetail}
      }

    }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: 6.00rem;

    /*  margin-top: 0.6rem;*/

    /*background-color: #F0F0F0;*/
    background-color: white;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;

    overflow-x: hidden;
  }

  .titleView{
    width: 100%;
    height: 1.23rem;
    margin-top: 0.38rem;
    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .centerView{
    width: 14.4rem;
    height: 3.3rem;
    margin-top: 0.31rem;

    display: flex;
    flex-direction: row;

    &__left{
      width: 4.666rem;
      height: 100%;

      display: flex;
      flex-direction: column;

    }

    &__center{
      width: 4.666rem;
      height: 100%;
      margin-left: 0.2rem;

      display: flex;
      flex-direction: column;
    }

    &__right{
      width: 4.666rem;
      height: 100%;
      margin-left: 0.2rem;

      display: flex;
      flex-direction: column;
    }


  }


  .news_item{
    background-color: #f3f3f3;
  }
  /*鼠标放置，激活效果*/
  .active{
    background-color: white;

    /*element-plus 阴影效果*/
    box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.12),0 0 0.12rem rgba(0,0,0,0.04);
    z-index: 999;
  }


</style>
