<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 100%; height: 2.6rem;position: absolute;"
        src="../../assets/product/product_banner@2x.jpg"
        alt />
    </view>

    <!--面包屑-->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">-->
    <el-breadcrumb separator="》"
      style="
        width: auto;
        height: 0.15rem;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    >
      <el-breadcrumb-item :to="{ path: '/' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          首页
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          产品中心
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/products' }">
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          "
        >
          壁挂式管线机
        </text>

      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <text
          style="
            width: 2.12rem;
            height: 0.15rem;
            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #45A2FF
          "
        >
          产品详情
        </text>

      </el-breadcrumb-item>
    </el-breadcrumb>

    <!--横线-->
    <view
      style="
        width: 14.4rem;
        height: 0.01rem;
        background: #CCCCCC;
        margin-left: 2.40rem;
        margin-top: 0.2rem;
      "
    />

    <!--简介-->
    <view class="introduceView">

      <!--  以下从首页拷贝过来    -->
      <view class="devView_item_bottom">

        <!--   设备图    670-28-28=614   340-28-28=284 background: #F0F6FC; -->
        <el-image
          :src="require('../../assets/product/product_water_bg_1.png')"
          fit="contain"
          style="
              width: 4.6rem;
              height: 4rem;

              position:absolute;
              top: 0;
              left: 0rem;

              padding: 0rem;

            "
        />

        <view class="devView_item_bottom_right">

          <text
            style="
                width: auto;
                height: 0.2rem;
                font-size: 0.2rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #000000;


                margin-top: 0.68rem;
                margin-left: 0.71rem;

            ">
            壁挂式管线机
          </text>

          <text
            style="
                width: 6.0rem;
                height: 1.1rem;
                font-size: 0.16rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                line-height: 0.52rem;
                opacity: 0.7;

                margin-top: 0.29rem;
                margin-left: 0.71rem;
                margin-right: 0.60rem;

            ">
            3秒速热&emsp;&emsp;即饮即热&emsp;&emsp;防干烧更安全
          </text>


        </view>



      </view>

    </view>





    <!-- 图片   -->
    <el-image
      :src="require('../../assets/product/product_water_bg_2.png')"
      fit="contain"
      style="
        width: 10.4rem;
        height: auto;
        margin-left: 3.6rem;
        margin-top: 1.2rem;
        "
      lazy
    />

    <!-- 图片   -->
    <el-image
      :src="require('../../assets/product/product_water_bg_3.png')"
      fit="contain"
      style="
        width: 10.4rem;
        height: auto;
        margin-left: 3.6rem;
        margin-top: 1.2rem;
        "
      lazy
    />

    <!-- 图片   -->
    <el-image
      :src="require('../../assets/product/product_water_bg_4.png')"
      fit="contain"
      style="
        width: 8rem;
        height: auto;
        margin-left: 4.8rem;
        margin-top: 1.2rem;
        "
      lazy
    />

    <!-- 图片   -->
    <el-image
      :src="require('../../assets/product/product_water_bg_5.png')"
      fit="contain"
      style="
        width: 8rem;
        height: 7.8231rem;
        margin-left: 4.8rem;
        margin-top: 1.2rem;
        "
      lazy
    />




  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  export default {
    name: "Product_6",

    setup() {
      const router = useRouter()


      //查看详情
      const handleLookDetail = () => {
        router.push(`/solutions`)
      }


      return {handleLookDetail}
    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/


    border-radius: 0;
  }

  /*通用标题*/
  .itemTitle{
    width: auto;
    height: 0.7rem;
    margin-left: 2.4rem;
    position: relative;

    &_img{
      width: 2.29rem;
      height: 0.7rem;

      position:absolute;
      top: 0;
      left: 0rem;

    }

    &_txt{
      width: auto;
      height: 0.23rem;
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;

      position: absolute;
      left: 0.82rem;
      top: 0.14rem;
    }

  }

  /*标题图片栏*/
  .titleView{
    width: 100%;
    height: 2.60rem;
    /*background-color: red;*/
  }

  /*简介*/
  .introduceView{
    width: 14.40rem;
    height: 4rem;
    margin-left: 2.4rem;

    margin-top: 0.4rem;

    /*background-color: red;*/
  }

  .devView_item_bottom{
    width: 100%;
    height: 100%;

    position: relative;

    /*670+50=720*/
    &_right{
      width: 7.10rem;
      height: 2.5rem;
      background: #F0F0F0;

      position: absolute;
      top:0;
      left: 5.6rem;

      display: flex;
      flex-direction: column;

    }

  }

  /* 设计标准 */
  .designStandardPicview{
    width: 14.40rem;
    height: 1.78rem;
    margin-left: 2.4rem;

    margin-top: 0.41rem;

    position: relative;

    &_left{
      width: 7.10rem;
      height: 1.78rem;

      position: absolute;
      left: 0;
      top:0;

      /*background-color: red;*/
    }

    &_right{
      width: 7.10rem;
      height: 1.78rem;

      position: absolute;
      right: 0;
      top:0;

      /*background-color: blue;*/
    }

  }


  /* 智慧管理 */
  .intelligentManager{
    width: 14.40rem;
    height: 1.60rem;
    margin-left: 2.05rem;  /* 2.4 +1.29 */

    margin-top: 0.59rem;
  }

  /* 智能控制系统 */
  .intelligentControlSystemView{
    width: 14.40rem;
    height: 2.377rem;
    margin-left: 2.05rem;

    margin-top: 0.4rem;

    position: relative;

    &_left{
      width: 7.10rem;
      height: 2.377rem;

      position: absolute;
      left: 0;
      top:0;

      /*background-color: red;*/
    }

    &_right{
      width: 7.10rem;
      height: 2.377rem;

      position: absolute;
      right: 0;
      top:0;

      /*background-color: blue;*/
    }

  }


  /* 应用场景 */
  .applyScenePicItemView{
    width: 14.40rem;
    height: 4.60rem;
    margin-left: 2.4rem;

    display: flex;
    flex-direction: row;

    &_left{
      width: 4.60rem;
      height: 4.60rem;

      position: relative;
    }

    &_center{
      width: 4.60rem;
      height: 4.60rem;
      margin-left: 0.2rem;

      position: relative;
    }

    &_right{
      width: 4.60rem;
      height: 4.60rem;
      margin-left: 0.2rem;

      position: relative;
    }

  }
  .applyScenePicItemView_txt{
    width: 100%;
    height: 0.4rem;

    background: #000000;
    opacity: 0.6;

    position: absolute;
    left: 0;
    bottom: 0;

    font-size: 0.2rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;

    line-height: 0.4rem;

  }



</style>
