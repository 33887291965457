<template>
  <div class="wrapper">

    <!-- 顶部导航 -->
    <Header :currentIndex="0"/>

    <!-- banner -->
    <Banner/>

    <!-- 解决方案 -->
    <Solution/>



    <!-- 产品中心 -->
    <ProductCenter/>

    <!-- 案例中心 -->
    <!--    <ProjectCaseCenter/> 先不开发-->

    <!--创美优势 add 7.28-->
    <Advantages/>

    <!--资质荣誉-->
    <Honor/>

    <!-- 新闻资讯 -->
    <NewsInfo/>

    <!-- 底部栏 -->
    <Footer @backTop="backTop()" @showMsgDialog="showMsgDialog(true)" :topMargin="0"/>

    <!-- 结合ElementUI实现回到顶部 2/2 绑定一个盒子  ok-->
    <!--<el-backtop target=".wrapper" :visibility-height="100"></el-backtop>-->

  </div>

  <!--  留言版  -->
  <div v-if="isShowMsgDialog" class="leave-msg-dialog">
    <MessageBoard @closeMsgDialog="showMsgDialog(false)"/>
  </div>

</template>

<script>
import Header from "../../components/Header";
import Banner from "./Banner";
import Solution from "./Solution";
import ProductCenter from "./ProductCenter";
//import ProjectCaseCenter from "./ProjectCaseCenter";
import NewsInfo from "./NewsInfo";
import Footer from "../../components/Footer";
// import { ref } from 'vue'


// 回到顶部
import { onMounted, onUnmounted } from 'vue'
import backToTopEffect from '../../effects/backToTopEffect'

//留言
import { ref } from 'vue'
import MessageBoard from "../../components/MessageBoard";
import Advantages from "./Advantages";
import Honor from "./Honor";

export default {

  name: 'Home',
  // components: {Header, Banner, Solution, ProductCenter, ProjectCaseCenter, NewsInfo, Footer}, 案例中心和新闻资讯，先不开发
  components: {Header, Banner, Solution, ProductCenter, Footer, MessageBoard, NewsInfo, Advantages, Honor },


  setup() {

    //回到顶部
    const { addLisener, removeLisener, backTop } = backToTopEffect();

    onMounted(() => {
      //console.log('onMounted-----------------------------------Home.vue')

      // 回到顶部 滚动条所在节点，添加监听
      const scrollDom = Array.from(document.getElementsByClassName('wrapper'));
      addLisener(scrollDom)

    });

    onUnmounted(() => {
      //console.log('onUnmounted-----------------------------------Home.vue')

      //回到顶部 滚动条所在节点，移除监听
      removeLisener();

      //关闭留言？

    });

    //留言
    const isShowMsgDialog = ref(true)//是否显示留言板
    const showMsgDialog = (showit) => {
      isShowMsgDialog.value = showit;
    }

    return {backTop , isShowMsgDialog, showMsgDialog}

  }



}
</script>


<style lang="scss" scoped> /*scoped： 表示 样式只在当前组件有效（本文件）*/
@import '../../style/viriables.scss';

.wrapper {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 0 .0rem .0rem .0rem;

  /*  结合ElementUI实现回到顶部 1/2 这里给app一个滚动效果 ok*/
  /*overflow-y: scroll;*/

}


</style>
