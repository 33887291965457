<!--行业新闻-->

<template>
  <div class="rootView">

    <view class="viewsItem" style="margin-top: 0.8rem"
          :class="{'news_item':true, 'active':itemPositon== 5}"
          @mouseover="() => handleItemMouseOver(5)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(5)">

      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/hangye/hangye-news-5.jpg')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          直饮水为何比开水更安全

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          中国人有个标志性习惯，那就是烧开水喝，大家普遍认为喝开水比较健康安全。但有专家在接受采访时说:“自来水煮沸饮用并不代表安全。"相比较而言，直饮水才是更好更安全的喝水方式。

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.81rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2022.07.29
        </text>
      </view>

    </view>


    <!--  横线  -->
    <view v-show="true"
      style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"

    />

    <view v-show="true"
      class="viewsItem" style="margin-top: 0.41rem"
      :class="{'news_item':true, 'active':itemPositon== 4}"
      @mouseover="() => handleItemMouseOver(4)"
      @mouseleave="() => handleItemMouseOver(0)"
      @click="() => handleItemClick(4)"
    >
      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/hangye/hangye-news-4.jpg')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          管道直饮水的优势
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          随着经济的高速发展，水体环境污染相当严重，高品质的饮水已经成为民众的共同需求。为了提高饮水质量，人们想了许多办法，近年来家用净水器、社区饮水站、桶装水等行业飞速发展，但综合比较，优质水好的供应方式还是管道直饮水，在保障饮水安全方面，管道直饮水拥有无可比拟的优势。
        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.81rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2022.07.29
        </text>
      </view>

    </view>


    <!--  横线  -->
    <view v-show="true"
          style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"

    />

    <view v-show="true"
          class="viewsItem" style="margin-top: 0.41rem"
          :class="{'news_item':true, 'active':itemPositon== 3}"
          @mouseover="() => handleItemMouseOver(3)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(3)"
    >
      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/hangye/hangye-news-3.jpg')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          超滤 纳滤和RO反渗透的区别

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          超滤、纳滤和RO反渗透的区别有哪些？

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.81rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2022.07.29
        </text>
      </view>

    </view>

    <!--  横线  -->
    <view v-show="false"
          style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"

    />

    <view v-show="false"
          class="viewsItem" style="margin-top: 0.41rem"
          :class="{'news_item':true, 'active':itemPositon== 2}"
          @mouseover="() => handleItemMouseOver(2)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(2)"
    >
      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/news-2.png')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          为什么选择管道直饮水？

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          管道直饮水，兴起于90年代中期，由中央净水设备在自来水提供的原水基础上经过再净化过程提供给人们饮用。 我们为什么要选择管道直饮水？ 它相对于传统饮用水方式，有哪些优点呢？

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.81rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2021.08.16
        </text>
      </view>

    </view>

    <!--  横线  -->
    <view v-show="true"
          style="
        width: 14.40rem;
        height: 0.01rem;
        margin-left: 2.4rem;
        margin-top: 0.41rem;
        background:  #CCCCCC;"

    />

    <view v-show="true"
          class="viewsItem" style="margin-top: 0.41rem"
          :class="{'news_item':true, 'active':itemPositon== 1}"
          @mouseover="() => handleItemMouseOver(1)"
          @mouseleave="() => handleItemMouseOver(0)"
          @click="() => handleItemClick(1)"
    >
      <view class="viewsItem__left">
        <el-image
          :src="require('../../assets/news/news-2.png')"
          fit="cover"
          style="
            width: 100%;
            height: 100%;
          "
          lazy
        />
      </view>
      <view class="viewsItem__right">
        <text
          style="
            width: auto;
            height: 0.2rem;
            margin-top: 0.4rem;

            font-size: 0.2rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            ">
          为什么选择管道直饮水？

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.55rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          管道直饮水，兴起于90年代中期，由中央净水设备在自来水提供的原水基础上经过再净化过程提供给人们饮用。 我们为什么要选择管道直饮水？ 它相对于传统饮用水方式，有哪些优点呢？

        </text>

        <text
          style="
            width: auto;
            height: 0.16rem;
            margin-top: 0.81rem;

            font-size: 0.16rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            line-height: 0.32rem;

            ">
          2021.08.16
        </text>
      </view>

    </view>






  </div>
</template>

<script>
  import { useRouter } from 'vue-router'// CompositionAPI 中使用 Vue-Router
  //import { ref } from 'vue'

  import {ref} from "vue";

  export default {
    name: "News_1",

    setup() {

      const router = useRouter()

      const itemPositon = ref('1')//tag编号

      //item点击函数定义
      const handleItemClick = (itemId) => {
        console.log("handleItemClick=======> itemId: "+itemId);

        /*新闻 item点击*/
        if(itemId == 1){
          router.push(`/news_hangye_detail_1`)
        }
        else if(itemId == 2){
          router.push(`/news_hangye_detail_2`)
        }
        else if(itemId == 3){
          router.push(`/news_hangye_detail_3`)
        }
        else if(itemId == 4){
          router.push(`/news_hangye_detail_4`)
        }
        else if(itemId == 5){
          router.push(`/news_hangye_detail_5`)
        }

      }

      //item鼠标上移函数定义
      const handleItemMouseOver = (positon) => {
        itemPositon.value = positon;
      }


      return { handleItemClick, itemPositon, handleItemMouseOver}

    }

  }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: auto;  /* 900-80 = 820   底部栏统一设置：margin-top:80了 */
    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items: center;*/
  }

  .viewsItem{
    width: 14.40rem;
    height: 3.0rem;
    margin-left: 2.4rem;

    display: flex;
    flex-direction: row;

    &__left{
      width: 5.0rem;
      height: 100%;

    }
    &__right{
      width: 8.3rem;
      height: 100%;
      margin-left: 0.7rem;

      display: flex;
      flex-direction: column;
    }

  }

  .news_item{
    background-color: white;
  }
  /*鼠标放置，激活效果*/
  .active{
    background-color: #f0f0f0;

    /*element-plus 阴影效果*/
    box-shadow: 0 0.05rem 0.1rem rgba(0,0,0,0.12),0 0 0.12rem rgba(0,0,0,0.04);
    z-index: 999;
  }


</style>
