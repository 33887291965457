<template>
  <div class="rootView">

    <!-- 标题栏 -->
    <view class="titleView">
      <img
        style="width: 2.52rem; height: 0.1rem;position: absolute;"
        src="../../assets/home/title_bg@2x.png"
        alt />

      <text
        style="
          width: 1.12rem;
          height: 0.29rem;
          font-size: 0.28rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          position: absolute;
        ">
        解决方案
      </text>
    </view>

    <view class="textView">
      <text
        style="
          width: 14.4rem;
          height: 0.49rem;
          font-size: 0.16rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 0.32rem;
        ">
          <!--     line-height: 0.32rem; 文字的行高    -->
        创美管道直饮水，采用先进的反渗透及臭氧紫外线复合杀菌技术，不同水源采用不同水处理工艺，使水质达到国家标准GB19298-2014 《食品安全国家标准 包装饮用水》，通过食品卫生级不锈钢循环管道输送到每家每户，为用户提供安全可靠的直饮水。
      </text>
    </view>

    <view class="bottomView">
      <view class="bottomView_left">
        <view
          style="
            width: 0.04rem;
            height: 0.2rem;
            background-color: #3399FF;

            position:absolute;
            top:0.05rem;
            left: 0;
          ">
        </view>

        <text
          style="
            width: 1.62rem;
            height: 0.19rem;
            font-size: 0.18rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3399FF;

            position:absolute;
            top:0.05rem;
            left: 0.1rem;
          ">
          智慧管道直饮水系统
        </text>

        <text
          style="
            width: auto;
            height: auto;
            font-size: 0.18rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;

            position:absolute;
            top:0.6rem;
            left: 0.1rem;
          ">
          解决方案1
        </text>

      </view>

      <view class="bottomView_center">
        <el-image
          @click="handleLookDetail()"

          style="width: 100%;height: 100%"
          :src="require('../../assets/home/bg_system@2x.png')"
          fit="contain"
          lazy
        />
      </view>

      <view class="bottomView_right">

<!--        <text-->
<!--          style="-->
<!--            width: auto;-->
<!--            height: auto;-->

<!--            font-size: 0.2rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: bold;-->
<!--            color: #000000;-->

<!--            position:absolute;-->
<!--            top:0.6rem;-->
<!--            left: 0.52rem;-->
<!--          ">-->
<!--          智慧管道直饮水系统-->
<!--        </text>-->

<!--        <text-->
<!--          style="-->
<!--            width: 4.85rem;-->
<!--            height: 0.48rem;-->

<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 1.20rem;-->
<!--            left: 0.52rem;-->
<!--          ">-->
<!--          运用物联网技术实现掌上充值、费用自动结算、数据实时采集、远程控制、会员服务、故障预警等功能数据化、可视化管理。-->
<!--        </text>-->

<!--        &lt;!&ndash;   掌上充值     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon1@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 2.15rem;-->
<!--            left: 0.75rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 3.11rem;-->
<!--            left: 0.83rem;-->
<!--          ">-->
<!--          掌上充值-->
<!--        </text>-->


<!--        &lt;!&ndash;   费用结算     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon2@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 2.15rem;-->
<!--            left: 2.53rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 3.11rem;-->
<!--            left: 2.60rem;-->
<!--          ">-->
<!--          费用结算-->
<!--        </text>-->

<!--        &lt;!&ndash;   数据采集     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon3@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 2.15rem;-->
<!--            left: 4.31rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 3.11rem;-->
<!--            left: 4.39rem;-->
<!--          ">-->
<!--          数据采集-->
<!--        </text>-->

<!--        &lt;!&ndash;   远程控制     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon4@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 3.69rem;-->
<!--            left: 0.75rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 4.65rem;-->
<!--            left: 0.83rem;-->
<!--          ">-->
<!--          远程控制-->
<!--        </text>-->

<!--        &lt;!&ndash;   会员服务     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon5@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 3.69rem;-->
<!--            left: 2.53rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 4.65rem;-->
<!--            left: 2.61rem;-->
<!--          ">-->
<!--          会员服务-->
<!--        </text>-->

<!--        &lt;!&ndash;   故障预警     &ndash;&gt;-->
<!--        <el-image-->
<!--          :src="require('../../assets/home/system_icon6@2x.png')"-->
<!--          fit="cover"-->
<!--          style="-->
<!--            width: 0.8rem;-->
<!--            height: 0.8rem;-->

<!--            position:absolute;-->
<!--            top: 3.69rem;-->
<!--            left: 4.31rem;-->
<!--          "-->
<!--          lazy-->
<!--        />-->
<!--        <text-->
<!--          style="-->
<!--            width: 0.64rem;-->
<!--            height: 0.15rem;-->
<!--            font-size: 0.16rem;-->
<!--            font-family: Microsoft YaHei;-->
<!--            font-weight: 400;-->
<!--            color: #000000;-->
<!--            line-height: 0.32rem;-->

<!--            position:absolute;-->
<!--            top: 4.65rem;-->
<!--            left: 4.39rem;-->
<!--          ">-->
<!--          故障预警-->
<!--        </text>-->

        <el-image
          style="
            width: 85%;
            height: 100%;
            margin-left: 7.5%;
          "
          :src="require('../../assets/solutions/solutions_pic_gaiyao.png')"
          fit="contain"
          lazy
        />


      </view>
    </view>

  </div>
</template>

<script>
    import {useRouter} from "vue-router";

    export default {
      name: "Solution",

      setup() {

        const router = useRouter()

        //查看详情
        const handleLookDetail = () => {
          router.push(`/solutions`)
        }

        return {handleLookDetail}
      }

    }
</script>

<style lang="scss" scoped>
  @import '../../style/viriables.scss';

  .rootView{
    width: 100%;
    height: 9rem;
    /*background-color: #F0F0F0;*/
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    /*align-items: center;*/

  }

  .titleView{
    width: 100%;
    height: 1.23rem;
    margin-top: 0.37rem;
    /*background-color: red;*/

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
  }

  .textView{
    width: 100%;
    height: .49rem;
    /*background-color: blue; 121.35.102.98 */

    display: flex;
    justify-content: center;
  }

  .bottomView{
    width: 100%;
    height: 5.20rem;
    margin-top: 0.93rem;
    /*background-color: yellow;*/

    display: flex;
    flex-direction: row;
    justify-content: center;

    &_left{
      width: 1.87rem;
      height: 100%;
      /*background-color: red;*/

      position: relative;

    }
    &_center{
      width: 6.38rem;
      height: 100%;
      /*background-color: #FFFFFF;*/
    }
    &_right{
      width: 5.90rem;
      height: 100%;
      margin-left: 0.21rem;
      background-color: #FFFFFF;

      position: relative;
    }



  }

</style>
